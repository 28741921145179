const config = {
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  accountApiUrl: process.env.REACT_APP_ACCOUNT_API_URL,
  resourceApiUrl: process.env.REACT_APP_RESOURCE_API_URL,
  calendarApiUrl: process.env.REACT_APP_CALENDAR_API_URL,
  noteApiUrl: process.env.REACT_APP_NOTE_API_URL,
  videoApiUrl: process.env.REACT_APP_VIDEO_API_URL,
  chatRoomApiUrl: process.env.REACT_APP_CHATROOM_API_URL,
  chatRoomSocketDomain: process.env.REACT_APP_CHATROOM_SOCKET_DOMAIN,
  chatRoomSocketPath: process.env.REACT_APP_CHATROOM_SOCKET_PATH,
  reportApiUrl: process.env.REACT_APP_REPORT_API_URL,
  dataResetApiUrl: process.env.REACT_APP_DATARESET_API_URL,
  statisticsApiUrl: process.env.REACT_APP_STATISTICS_API_URL,
  appStoreUrl: process.env.REACT_APP_APP_STORE_URL,
  googlePlayUrl: process.env.REACT_APP_GOOGLE_PLAY_URL,
};

export default config;
