function Female() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4693_22172" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
        <circle r="59.5" transform="matrix(-1 0 0 1 60 60)" fill="white" stroke="black" />
      </mask>
      <g mask="url(#mask0_4693_22172)">
        <path fillRule="evenodd" clipRule="evenodd" d="M39.129 8.3265L27.7458 14.998C27.7458 14.998 9.85695 24.2961 10.6909 41.1383C10.7357 42.0426 10.7789 42.8864 10.8192 43.6754C11.1721 50.5744 11.3105 53.281 10.4447 55.6151C9.88567 57.122 8.90809 58.4736 7.29939 60.6977C6.37778 61.972 5.24902 63.5326 3.87316 65.5728C-0.277834 71.7284 1.98121 75.7602 4.23332 79.7797C6.27969 83.432 8.32033 87.0741 5.54112 92.2898C2.55965 97.8879 1.55887 101.9 2.26775 105.841C3.49787 112.784 10.1071 116.868 17.4879 116.076C22.5334 115.547 27.5581 118.262 31.9574 122.875C36.645 127.775 44.087 126.493 49.9911 124.243C67.135 132.265 78.3058 124.167 78.3058 124.167C91.5514 130.094 101.237 114.287 101.237 114.287C114.669 115.561 126.694 98.9741 115.704 87.3269C110.009 81.2911 110.272 74.4594 110.522 67.9534C110.755 61.9047 110.977 56.1375 106.391 51.5531C101.669 46.8438 101.793 43.0897 101.944 38.529C102.098 33.8746 102.28 28.3802 97.3671 20.173C91.9286 11.1028 84.5816 7.84324 79.1602 6.73217C75.3154 2.67619 68.5105 -0.685512 56.4666 0.120164C54.8938 0.226994 53.4129 0.455626 52.0196 0.785047C52.0162 0.778052 52.0145 0.774538 52.0145 0.774538L51.981 0.794209C46.3717 2.12944 42.1841 5.09881 39.129 8.3265Z" fill="#6A6A6A" />
        <path d="M40.5734 47.9607C40.5734 47.9607 26.9668 52.689 25.4633 65.1177C23.9598 77.5463 24.6629 74.5345 17.6924 82.2157C10.7218 89.897 20.9683 94.6284 15.5476 101.972C12.7789 105.724 11.5855 108.545 11.5915 111.513C11.5888 116.74 15.6854 120.499 20.9228 120.776C24.5014 120.976 27.6642 123.548 30.1565 127.437C35.1211 135.157 49.4482 128.198 49.4482 128.198C49.4482 128.198 64.1163 132.998 72.0398 125.331C79.9777 117.667 59.2319 40.3677 59.2319 40.3677L40.5734 47.9607Z" fill="#565656" />
        <path fillRule="evenodd" clipRule="evenodd" d="M94.3599 57.9667C97.3289 45.8013 86.2484 36.6381 86.2484 36.6381L71.4445 23.0339C71.4445 23.0339 64.3262 32.9798 55.9326 45.6942H63.1152V116.195C64.8166 116.259 66.4928 115.847 68.0153 114.71C71.7197 111.934 75.5882 110.625 79.0107 111.683C84.0144 113.246 89.1757 111.15 91.014 106.253C92.0648 103.475 91.9406 100.417 90.6686 95.9387C89.4824 91.7662 91.414 89.3836 93.351 86.9943C95.4827 84.3647 97.621 81.7271 95.6174 76.6866C93.8077 72.1341 92.7643 70.4225 92.4818 68.5933C92.1674 66.5571 92.7959 64.3752 94.3599 57.9667Z" fill="#565656" />
        <path d="M79.6818 17.6756C79.6818 17.6756 78.6526 28.2212 66.7729 29.8946C54.6489 31.6028 57.2831 38.6796 42.5425 36.9714C42.5425 36.9714 37.2219 29.267 45.1068 21.3186C50.3227 16.0546 60.5277 11.662 67.837 12.4116C70.3141 12.6556 76.1406 16.961 79.6818 17.6756Z" fill="#6A6A6A" />
        <path d="M70.0893 110.529C70.0369 110.598 69.9672 110.651 69.8974 110.686C69.1298 111.191 68.1355 111.139 67.2284 111.034C61.4368 110.424 56.0814 108.542 50.7957 106.223C49.3303 105.578 34.9386 106.52 34.4502 106.781C34.4502 106.781 45.6147 101.831 47.8476 97.8741C51.476 91.4073 52.418 74.9353 47.7254 67.7189C41.6897 58.4284 54.4765 68.0327 54.5114 66.7603C54.5288 65.7144 54.1974 64.686 53.6391 63.8145C59.7796 69.4969 65.7979 75.3187 71.8861 81.0883C71.8861 81.0883 71.2755 87.9909 70.5079 96.4447C70.2637 98.8328 71.7988 108.699 70.0893 110.529Z" fill="#C6C6C6" />
        <path d="M53.1142 71.537C53.1665 70.6655 53.2537 69.794 53.3758 68.8876L74.1522 68.2949C74.1522 68.2949 72.8788 77.08 71.3262 87.8173C70.7854 91.5649 71.2913 95.3299 70.7157 99.2693C70.4889 100.786 58.8709 91.3034 58.1033 90.153C54.4225 84.6623 52.6955 78.1084 53.1142 71.537Z" fill="#8F8989" />
        <path fillRule="evenodd" clipRule="evenodd" d="M115.49 148H2.80957L6.54029 115.982C7.77368 108.501 13.0216 103.054 19.5734 101.471C19.8232 101.27 20.0588 101.124 20.2765 101.039L61.3646 94L102.415 104.774C102.692 104.843 102.955 104.919 103.206 105.001C106.053 105.045 109.116 106.551 110.554 108.715C112.85 112.162 113.395 116.645 113.895 120.756L113.895 120.757C113.953 121.234 114.01 121.706 114.07 122.171C114.657 126.894 114.786 131.618 114.916 136.341C115.022 140.227 115.129 144.114 115.49 148Z" fill="#A3A6A6" />
        <path d="M84.2522 39.881C85.4384 49.3285 85.1942 57.6429 84.6709 63.5694C84.1475 69.3912 81.3041 74.7599 76.8209 78.5598L76.001 79.257C71.2561 83.2661 64.5923 84.1899 58.9403 81.5927C53.2359 78.9781 48.8748 74.1324 46.9733 68.1885C43.5193 57.3989 41.7574 45.18 41.7574 45.18C41.7574 45.18 36.8032 21.5962 59.7776 19.1908C82.7694 16.7853 84.2522 39.881 84.2522 39.881Z" fill="#C6C6C6" />
        <path d="M63.373 15.7927C63.373 15.7927 58.4711 46.3836 48.5801 50.6716C38.6891 54.9595 40.9093 60.4098 40.9093 60.4098C40.9093 60.4098 26.8841 58.4736 42.3873 26.7915C50.3594 17.5358 63.373 15.7927 63.373 15.7927Z" fill="#6A6A6A" />
        <path d="M48.1495 54.0282C48.7976 58.3411 47.0412 62.2167 44.2275 62.6908C41.4163 63.1476 38.6107 60.0329 37.9626 55.72C37.3145 51.4071 39.071 47.5315 41.8847 47.0574C44.6983 46.5833 47.5015 49.7153 48.1495 54.0282Z" fill="#C6C6C6" />
        <path d="M54.6504 22.9042C54.6504 22.9042 68.9723 25.1004 75.4617 28.726C85.091 34.0947 85.9458 42.8797 85.1085 48.3181C85.1085 48.3181 88.388 23.1656 73.9091 15.5135C58.558 7.40826 54.6504 22.9042 54.6504 22.9042Z" fill="#6A6A6A" />
        <path d="M48.1416 95.7943L59.6724 93.1797L71.6746 97.9765C71.6746 97.9765 71.9882 108.17 63.1787 109.216C52.1364 110.541 48.1416 95.7943 48.1416 95.7943Z" fill="#C6C6C6" />
        <path d="M47.446 97.3853C48.8939 100.61 50.8128 103.748 53.2376 106.327C55.6275 108.855 58.7849 109.535 62.1691 109.691C65.0126 109.831 68.17 109.308 70.054 106.972C72.1823 104.34 72.6009 100.244 72.1474 97.0193C71.9904 95.9211 70.3157 96.3917 70.4727 97.4899C70.8565 100.227 70.4378 104.166 68.3968 106.24C66.5651 108.088 63.617 108.105 61.2097 107.896C59.7443 107.757 58.1395 107.582 56.7962 106.92C55.5402 106.292 54.5459 105.247 53.6737 104.183C51.7548 101.865 50.2022 99.2678 48.9637 96.5312C48.4927 95.4679 46.975 96.3569 47.446 97.3853Z" fill="#A2A2A2" />
        <path d="M55.3753 16.5114C57.7849 23.6542 65.5786 25.5824 71.8103 27.8628C75.3 29.1371 78.474 30.8976 80.9999 33.6978C83.3098 36.2632 84.9051 39.3986 85.6529 42.7688C85.8856 43.8252 87.4975 43.3724 87.2482 42.3161C85.736 35.5925 81.0664 30.0257 74.8347 27.2759C68.7028 24.5764 59.4633 23.4698 56.9706 16.0587C56.6383 15.0526 55.0264 15.4886 55.3753 16.5114Z" fill="#7E7E7E" />
        <path d="M59.5945 23.4046C62.7795 29.7084 57.9523 35.9358 53.4734 41.4899C52.7518 42.362 54.9166 43.1424 55.6133 42.2549C60.3908 36.3336 65.3921 29.7084 62.0081 22.9915C61.5105 22.0581 59.0969 22.456 59.5945 23.4046Z" fill="#7E7E7E" />
        <path d="M51.58 17.763C52.7662 21.04 50.9171 24.2473 48.8237 26.705C46.7479 29.1627 44.2533 31.2718 41.9332 33.503C37.4499 37.7909 33.1586 42.6541 31.92 48.9291C31.2746 52.2061 31.5537 55.5354 33.0539 58.5335C33.5598 59.5444 35.06 58.6555 34.5542 57.6619C31.6584 51.8749 34.2227 45.0769 37.9384 40.3358C41.8634 35.3332 47.341 31.725 51.109 26.6178C53.0802 23.9509 54.4409 20.5868 53.2546 17.3098C52.8883 16.264 51.1962 16.7172 51.58 17.763Z" fill="#7E7E7E" />
        <path d="M27.3313 59.6304C27.0696 62.7854 26.9126 66.1669 28.2733 69.1127C28.9362 70.5595 29.9828 71.675 31.2563 72.6163C31.9366 73.1043 32.6518 73.5401 33.3496 73.9933C33.9078 74.3593 35.0941 74.9694 35.3557 75.6318C35.6523 76.3465 34.5009 77.096 34.1172 77.6189C33.6113 78.2987 33.2101 79.0308 32.8961 79.8152C32.3029 81.3142 32.0587 82.9701 32.1808 84.5912C32.4425 88.0599 34.2218 91.1277 36.5245 93.6551C37.2746 94.4918 38.5132 93.2542 37.7631 92.4176C35.4081 89.8378 33.6113 86.5085 33.9253 82.9178C34.0648 81.2968 34.6405 79.6757 35.6872 78.4033C36.5245 77.3923 37.8677 76.573 37.1002 75.074C36.4373 73.8016 34.9719 72.93 33.8206 72.1805C32.4599 71.309 31.0993 70.4549 30.2445 69.0256C28.5524 66.2541 28.8489 62.6808 29.1106 59.5955C29.1629 58.4974 27.4185 58.5148 27.3313 59.6304Z" fill="#7E7E7E" />
        <path d="M92.3866 53.9933C93.3461 56.0501 93.6949 58.3684 93.3984 60.6344C93.2588 61.7848 92.9274 62.9352 92.4564 63.9985C91.9679 65.0792 91.1655 65.9508 90.6422 66.9966C90.0839 68.1122 90.5724 69.1406 90.9736 70.2213C91.4621 71.546 91.9505 72.8707 92.4389 74.1781C93.4333 76.8798 94.4451 79.599 95.4394 82.3008C95.8232 83.3466 97.5153 82.8934 97.1141 81.8301C96.0848 79.0586 95.0556 76.2872 94.0264 73.4983C93.5379 72.1735 93.0495 70.8488 92.5611 69.5415C92.2819 68.792 91.9331 68.1819 92.3866 67.4149C92.7006 66.892 93.1018 66.4214 93.4158 65.8985C95.6487 61.994 95.7534 57.1657 93.8519 53.1218C93.4158 52.0933 91.9156 52.9823 92.3866 53.9933Z" fill="#7E7E7E" />
        <path d="M43.2446 16.3353C43.3842 18.9674 42.3898 21.338 40.5058 23.1682C38.7963 24.8241 36.7204 26.0617 34.9061 27.5781C31.5219 30.3845 28.6261 33.9055 28.1028 38.4026C27.9807 39.5182 29.7251 39.5007 29.8472 38.4026C30.388 33.6963 34.2084 30.1579 37.8194 27.491C39.651 26.1314 41.5874 24.8415 42.9829 23.0113C44.4308 21.0939 45.1112 18.7233 44.9891 16.3353C44.9367 15.2023 43.1923 15.2023 43.2446 16.3353Z" fill="#7E7E7E" />
        <path d="M64.8907 11.7488C66.7398 10.947 68.7808 12.9515 69.8624 14.224C70.5078 14.9909 71.0486 15.8625 71.4847 16.7863C71.8162 17.4835 71.9034 18.6862 72.6186 19.0871C73.4909 19.5752 74.5026 19.8541 75.3923 20.3247C76.282 20.7779 77.1368 21.2834 77.9741 21.8412C79.7185 22.9916 81.2885 24.3686 82.7015 25.9025C83.4691 26.7218 84.6902 25.4842 83.9401 24.665C80.9571 21.4403 77.2763 19 73.1769 17.4487C73.3862 17.6578 73.5781 17.8496 73.7874 18.0587C72.7059 14.3286 68.6413 8.22781 64.0011 10.2498C62.9893 10.6855 63.8615 12.1846 64.8907 11.7488Z" fill="#7E7E7E" />
      </g>
    </svg>

  );
}

export default Female;
