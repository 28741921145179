import {NOT_FOUND, SURVEY_ROOT} from '@router/consts';
import Survey from '@pages/Survey';
import {RedirectSurvey} from '@pages/RedirectPage';

export const SURVEY_ROUTES = [
  {
    name: 'survey',
    path: SURVEY_ROOT,
    exact: true,
    component: Survey.SurveyStudent,
  },
  {
    name: 'redirect',
    path: NOT_FOUND,
    component: RedirectSurvey,
  },
];
