import {useMemo} from 'react';
import {AUTH_ROUTES} from '@router/auth';
import {SURVEY_STATUSES, USER_ROLES} from '@utils/consts';
import AuthLayout from '@components/Layout/AuthLayout';
import RootLayout from '@components/Layout/RootLayout';
import {ROOT_ROUTES} from '@router/root';
import {STUDENT_ROUTES} from '@router/student';
import {SURVEY_ROUTES} from '@router/survey';
import SurveyLayout from '@components/Layout/SurveyLayout';
import {COUNSELLOR_ROUTES} from '@router/counsellor';
import {RedirectRoot} from '@pages/RedirectPage';
import {NOT_FOUND} from '@router/consts';
import {PRINCIPAL_ROUTES} from '@router/principal';
import {ALLBRY_COUNSELLOR_ROUTES} from '@router/allbryCounsellor';

const surveyStatuses = {
  [SURVEY_STATUSES.STUDENT_SURVEY]: SURVEY_ROUTES,
};

const routeRoles = {
  [USER_ROLES.STUDENT_ROLE]: STUDENT_ROUTES,
  [USER_ROLES.COUNSELLOR_ROLE]: COUNSELLOR_ROUTES,
  [USER_ROLES.PRINCIPAL_ROLE]: PRINCIPAL_ROUTES,
  [USER_ROLES.ALLBRY_COUNSELLOR_ROLE]: ALLBRY_COUNSELLOR_ROUTES,
};

export const useRouterPaths = (role, surveyStatus, isMainLoading) => useMemo(() => {
  if (isMainLoading) return [];
  let allRoutes = [];
  let layout;

  if (surveyStatus && surveyStatuses[surveyStatus]) {
    layout = SurveyLayout;
    allRoutes = allRoutes.concat(allRoutes, surveyStatuses[surveyStatus]);
  }

  if (!surveyStatus && role && routeRoles[role]) {
    layout = role === USER_ROLES.ALLBRY_COUNSELLOR_ROLE ? AuthLayout : RootLayout;
    allRoutes = allRoutes.concat(allRoutes, [
      ...routeRoles[role],
      ...ROOT_ROUTES,
      {
        path: NOT_FOUND,
        component: RedirectRoot,
      },
    ]);
  }

  if (!surveyStatus && !role) {
    layout = AuthLayout;
    allRoutes = allRoutes.concat(allRoutes, AUTH_ROUTES);
  }

  return [{
    component: layout,
    routes: allRoutes,
  }];
}, [role, surveyStatus, isMainLoading]);
