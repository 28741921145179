import {renderRoutes} from 'react-router-config';
import {useContext} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import clsx from 'clsx';
import {Logo, Logout} from '@assets/icons';

import NavbarList from '@components/Navbar/NavbarList';
import {AdminContext} from '@admin/contexts/AdminContext';
import {useFetchHook} from '@hooks/useFetchHook';
import Loader from '@components/Loader';
import styles from './RootAdmin.module.scss';

const RootAdmin = ({route}) => {
  const menuItems = route.routes.filter((item) => item?.isMenu);
  const {logout} = useContext(AdminContext);
  const [attemptLogout, {isLoading}] = useFetchHook(logout);

  return (
    <div className="flex flex-row flex-1 h-screen">
      <div className={clsx('bg-main pb-16 pt-8', styles.nav)}>
        <Logo className="mb-16" />
        <div className="flex-1 w-full">
          <NavbarList navItems={menuItems} />
        </div>
        <div onClick={() => attemptLogout()} className={clsx('flex items-center cursor-pointer hover:opacity-80 transition duration-75', isLoading && 'pointer-events-none')}>
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              key={isLoading || 'empty'}
              animate={{opacity: 1}}
              initial={{opacity: 0}}
              exit={{opacity: 0}}
            >
              {isLoading
                ? <Loader stroke="var(--colors-silver)" height={35} width={35} />
                : <Logout height={35} width={35} />}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <div className={clsx('flex flex-1 p-12 bg-cararra', styles.viewLayout)}>{renderRoutes(route.routes)}</div>
    </div>
  );
};

export default RootAdmin;
