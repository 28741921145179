import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';

const Student = {
  Appointments: loadable(() => import('./Appointments'), loadableRootParams),
  AppointmentDetail: loadable(() => import('./AppointmentDetail'), loadableRootParams),
  CounsellorsList: loadable(() => import('./Counsellors'), loadableRootParams),
  Messages: loadable(() => import('./Messages'), loadableRootParams),
  Booking: loadable(() => import('./AppointmentBooking'), loadableRootParams),
};

export default Student;
