import {PRINCIPAL_ROUTE_NAME} from '@router/principal';

const sv = {
  common: {
    continue: 'Fortsätt',
    next: 'Nästa',
    saveChanges: 'Spara ändringar',
    save: 'Spara',
    upload: 'Ladda upp',
    loading: 'Laddar',
    back: 'Tillbaka',
    cancel: 'Avbryt',
    delete: 'Ta bort',
    close: 'Stäng',
    twentyMinutes: '20 min',
    fiftyMinutes: '50 min',
    resetPasswordError: 'Ett fel uppstod när lösenordet skulle återställas',
    notFoundEmailError: 'En användare med denna e-postadress kunde inte hittas',
    email: 'E-postadress',
    password: 'Lösenord',
    passwordConfirm: 'Bekräfta ditt lösenord',
    corporateEmail: 'Ange din skol- eller organisationsmail',
    today: 'Idag',
    scheduleSession: 'Boka ett kuratorsmöte',
    invalidEmail: 'Ogiltig e-postadress',
    invalidPasswordLength: '8 tecken långt',
    invalidPasswordMatch: 'En stor bokstav, en liten bokstav & en siffra',
    invalidPasswordConfirm: 'Lösenorden matchar inte',
    requiredField: 'Detta fält är obligatoriskt',
    no: 'Nej',
    checkSearch: 'Lägg till en ny eller kontrollera om sökningen är korrekt',
    noFound: 'Inga {{name}} hittades',
    switchAccountError: 'Ett fel inträffade när du försökte byta organisation',
    dragNDrop: 'Dra och släpp några filer hit, eller klicka för att välja filer.',
  },
  modal: {
    sizeLimit: {
      title: 'Gränsen för filstorlek har nåtts',
      content: 'Filen är för stor för uppladdning, gränsen är {{sizeLimit}}. Snälla, välj en annan.',
    },
    fileType: {
      title: 'Ogiltig filtyp',
      content: 'Du kan endast använda bilder som profilbild',
    },
  },
  mobile: {
    notAvailable: 'Webbversionen av Allbry är inte tillgänglig på mobilen',
    availableInLandscape: 'Allbry-webbversionen är endast tillgänglig i liggande läge',
    pleaseUseLinks: 'Ladda ner vår mobilapp via länken nedan',
    pleaseUseLinksLandscape: 'Du kan ladda ner vår mobilapp genom att använda länken nedan',
    appStore: 'App Store',
    googlePlay: 'Google Play',
  },
  introduction: {
    getSupport: 'Digital kurator direkt i mobilen',
    chat: {
      student: 'Aden',
      counsellor: 'Alexandra',
      messages: {
        first: 'Hej!',
        second: 'Jag känner mig nere',
        third: 'Och har ingen att prata med... 😔',
        fourth: 'Tja Aden! Du har ju mig att prata med! Berätta för mig, varför känner du dig nere?',
      },
    },
  },
  auth: {
    signUp: {
      startJourney: 'Påbörja din resa med Allbry idag',
      withOrganization: 'Registrera dig med ett organisationskonto (SSO)',
      withEmail: 'Registrera dig med en e-postadress',
      signUp: 'Registrera dig med en e-postadress',
      createPass: 'Skapa lösenord',
      haveAccount: 'Har du redan ett konto?',
      logIn: 'Logga in',
    },
    signIn: {
      welcomeBack: 'Välkommen tillbaka till Allbry',
      withOrganization: 'Logga in med organisationskonto (SSO)',
      withEmail: 'Logga in med e-postadress och lösenord',
      logIn: 'Logga in med e-postadress och lösenord',
      signUp: 'Registrera dig',
      doNotHaveAnAccount: 'Har du inget konto?',
    },
    forgot: {
      checkStep: 'Kolla din e-post!',
      weJustSent: 'Vi har skickat en',
      resetPassLink: 'återställningslänk för ditt lösenord',
      followInstructions: 'till din e-post. Kolla din inkorg och följ instruktionerna',
      enterEmail: 'Ange din e-postadress',
      createNewPass: 'Skapa nytt lösenord',
    },
    registrationError: 'Det gick inte att skapa ditt konto',
    signInError: 'Felaktigt e-postadress och/eller lösenord',
    forgotPassword: 'Glömt lösenord?',
    invalidPassword: 'Ogiltigt lösenord',
  },
  navbar: {
    switch: 'Ställ dig själv som',
    toOffline: 'Offline',
    toOnline: 'Online',
    appointments: {
      title: 'Samtal',
      text: 'Boka ett videosamtal med en kurator för ett personligare möte.',
    },
    messages: {
      title: 'Chatt',
      text: 'Chatta med en kurator när som helst utan att boka tid',
    },
    home: {
      title: 'Hem',
    },
    organizations: {
      title: 'Organisationer',
    },
    profile: 'Profil',
    settings: {
      title: 'Inställningar',
    },
    logOut: 'Logga ut',
    [PRINCIPAL_ROUTE_NAME.ORGANIZATION]: {
      title: 'Organisation',
    },
    [PRINCIPAL_ROUTE_NAME.STATISTICS]: {
      title: 'Statistik',
    },
    [PRINCIPAL_ROUTE_NAME.USERS]: {
      title: 'Användare',
    },
    counsellors: {
      title: 'Counsellors',
    },
  },
  survey: {
    introStep: 'Hej, låt oss börja med två snabba frågor!',
    firstStep: {
      question: 'Är det första gången du använder Allbry?',
      letMeIn: 'Nej, jag har använt Allbry förut',
      whatIsAllbry: 'Ja, det är första gången',
    },
    secondStep: {
      question: 'Har du tidigare varit i kontakt med din kurator på skolan?',
      yes: 'Ja, minst ett par gånger',
      never: 'Nej, det har jag inte',
    },
    outroStep: 'Tack för din tid!',
    helpImprove: 'Det kommer bara ta en minut och frågorna är helt anonyma!',
    firstPart: '1/2',
    secondPart: '2/2',
  },
  notFound: {
    code: '404',
    text: 'Sidan gick inte att visa',
  },
  settings: {
    title: 'Inställningar',
    anonymous: {
      title: 'Anonymt läge',
      description: 'Din organisation tillåter dig att vara anonym. Med denna inställning aktiverad kommer ditt namn och profilbild vara dolt för din kurator. Vi har anmälningsskyldighet i fall vi känner oss oroliga för dig och din omedelbara säkerhet',
    },
    dataReset: {
      recurrentReset: {
        title: 'Återkommande återställning av data',
        disabledResetParagraph: 'Aktivera återkommande återställning av data för att rensa data automatiskt. Observera att återställning av data påverkar chattar, möten och anteckningar.',
        enabledResetParagraph: 'Beräknat datum för nästa återställning är {{date}}. Observera att återställning av data påverkar chattar, möten och anteckningar.',
        intervalLabel: 'Intervall',
        intervals: {
          eachMonth: 'Varje månad',
          eachThreeMonths: 'Varje 3 månader',
          eachSixMonths: 'Varje 6 månader',
        },
        errorGettingResetState: 'Fel när det gäller att hämta status för aktuella återkommande jobb',
        errorSwitchingResetState: 'Fel vid ändring av status för återkommande jobb',
        errorIntervalChange: 'Fel vid ändring av återställningsintervallet',
        successIntervalChange: 'Ett nytt återställningsintervall har fastställts.',
      },
      manualReset: {
        title: 'Återställ data nu',
        paragraph: 'Observera att återställning av data påverkar chattar, möten och anteckningar.',
        resetButton: 'Rensa alla uppgifter',
        resetSuccess: 'Uppgifterna har raderats',
        resetFailure: 'Fel vid återställning av data',
        resetModal: {
          title: 'Rensa alla uppgifter',
          paragraph: 'Är du säker på att du vill återställa all information i chattar, möten och anteckningar?',
          yes: 'Ja',
          no: 'Nej',
        },
      },
    },
    policy: {
      title: 'Integritetspolicy',
      description: {
        firstPart: 'Eftersom vi är fullt medvetna om att vårt arbete som konsulter och ingenjörer är avgörande för att uppnå en hållbar utveckling av samhället och miljön, har vi utformat och genomfört en rad åtgärder som syftar till att göra vårt etiska beteende till ett av IRD Engineering huvudsakliga egenskaper.',
        secondPart: 'Alla dessa, och eventuella framtida åtgärder, är och kommer att införlivas i vårt integrerade ledningssystem, som utgör företagets officiella policy och förfaranden för att se till att företagets tjänster utförs på en hög professionell nivå, i enlighet med de högsta internationella standarderna när det gäller kvalitetssäkring, miljömässig hållbarhet och arbetstagarnas hälsa och säkerhet.',
      },
      policyCopy: 'En kopia av vår etiska kod och integritetspolicy finns',
      hereLink: 'här',
    },
    language: {
      title: 'Språk',
    },
  },
  profile: {
    personProfile: '{{type}}s profil',
    overview: 'Profil',
    edit: {
      title: 'Ändra profil',
      restriction: 'Endast administratörer kan ändra',
      gender: {
        male: 'Man',
        female: 'Kvinna',
        other: 'Annat',
      },
      about: 'Om',
      changesSaved: 'Ändringar sparade',
    },
    personalInfo: 'PERSONLIGA UPPGIFTER',
    about: 'Om mig',
    name: 'Namn',
    birthday: 'Födelsedatum',
    fullBirthday: '{{birthday}} ({{fullYears}} år)',
    appointments: 'SAMTAL',
    noAppointments: 'Inga utnämningar hittades för denna kategori',
    gender: 'Kön',
    age: 'Ålder',
    lastNote: '{{person}}s senaste anteckning kommer visas här.',
  },
  appointments: {
    title: 'Samtal',
    session: {
      today: 'Gå med i samtalet',
      future: 'Avbokning eller ombokning',
      completed: 'Boka ett nytt samtal',
      cancelled: ' Boka ett nytt samtal',
    },
    status: {
      upcoming: 'Kommande',
      completed: 'Avslutade',
      cancelled: 'Avbokade',
    },
    booking: {
      title: {
        booking: 'Bokning',
        reschedule: 'Omplanera',
      },
      schedule: 'Boka',
      chooseStudent: 'Välj elev',
      selectStudent: 'Välj elev',
      sessionDuration: 'Samtalets längd',
      sessionTime: 'Samtalets dag och tid',
      success: 'Klart',
      successText: 'Samtalet har blivit {{action}}! Du kan nu se samtalet under kommande',
      successBooked: 'bokat',
      successRescheduled: 'omdisponerad',
      back: 'Tillbaka till samtal',
      chooseStudentModal: 'Välj elev',
      done: 'Klart',
      chooseCounsellor: 'Välj kurator',
      chooseCounsellorModal: 'Välj kurator',
      selectCounsellor: 'Välj kurator',
      searchStudents: 'Sök elev',
      noTimeSlots: 'Inga lediga tider',
    },
    detail: {
      scheduleInfoCancelOrReschedule: 'Samtalet är bokat per {{date}}, klockan {{time}}. Du kan närsomhelst avboka eller omboka samtalet. ',
      scheduleInfoBookAnother: 'Samtalet är bokat per {{date}}, mellan kl {{start}} - {{end}}. Du kan alltid boka flera samtal.',
      reschedule: 'Omboka',
      cancel: 'Avboka',
      areYouSure: 'Är du säker på att du vill avboka ditt samtal?',
      yes: 'Ja, avboka',
      cancelled: 'Samtalet är avbokat',
      sessionDetails: 'Samtalets detaljer',
    },
    now: 'Nu',
    scheduleNew: 'Boka ett nytt samtal',
    counsellors: 'Kuratorer',
    appointments: 'Samtal',
    seeAll: 'Se alla',
    notExist: 'Samtalet finns inte',
    weeksInterval: 'Om {{interval}} veckor',
    daysInterval: 'Om {{interval}} dagar',
    dayInterval: ' Om {{interval}} dag',
    hoursInterval: ' Om {{interval}} timmar',
    hourInterval: ' Om {{interval}} timme',
    minutesInterval: ' Om {{interval}} minuter',
    minuteInterval: ' Om {{interval}} minut',
    today: 'Idag',
    future: 'Framtiden',
    completed: 'Avslutad',
    cancelled: 'Avbokad',
    organizationNotWorking: 'Organisationen arbetar inte denna dag',
    noAppointments: 'Inga möten här ännu...',
    scheduleFirst: 'Boka ditt första möte',
    yourFreeTime: 'Din fritid',
    freeTime: {
      title: 'Din fritid',
      addTime: 'Lägg till tid',
      noFreeTimes: 'Vänligen avsätt din lediga tid för att eleverna ska kunna boka tiderna',
      allocateTime: 'Tilldela ledig tid',
      editTime: 'Redigera ledig tid',
      add: 'Lägg till',
      cancel: 'Annullera',
      date: 'Datum',
      ends: 'Slutar',
      orgNotWorking: 'Organisationen fungerar inte',
      repeats: {
        everyday: 'Varje dag',
        weeklyOn: 'Varje vecka på {{day}}',
        weekdays: 'Varje vardag (måndag till fredag)',
        none: 'Upprepas inte',
      },
      deleteModal: {
        title: 'Ta bort ledig tid',
        description: 'Är du säker på att du vill radera din lediga tid?',
        delete: 'Ja, radera',
      },
      timeAdded: 'Din lediga tid lades till',
      timeEdited: 'Din lediga tid har redigerats',
      timeDeleted: 'Din lediga tid raderades',
    },
  },
  notes: {
    title: 'Minnesanteckningar',
    note: 'Anteckning',
    add: 'Skapa anteckning',
    personNotes: '{{person}}s anteckningar',
    personNote: '{{person}}s anteckning',
    create: 'Skapa ny anteckning',
    saveText: 'Skapa minnesanteckningar för eleven, dem är endast synliga för dig och andra kuratorer.',
    autoSaveText: 'Skapa minnesanteckningar för eleven, dem är endast synliga för dig och andra kuratorer. Anteckningarna sparas automatiskt. ',
    autoSaveTextDuringVideo: 'Skapa minnesanteckningar för eleven, dem är endast synliga för dig och andra kuratorer.Anteckningarna sparas automatiskt.',
    metaAppointment: '{{person}} har avslutat ett samtal med {{student}}, {{date}}',
    metaChat: '{{person}} har avslutat en chattkonversation med {{student}}, {{date}}',
    createdBy: '{{date}} av {{person}}',
    delete: 'Ta bort anteckningen',
    deleteQuestion: 'Är du säker på att du vill ta bort den här anteckningen?',
    yes: 'Ja, ta bort',
    saved: 'Anteckningen har sparats',
    deleted: 'Anteckningen har raderad',
  },
  videoCall: {
    leaveCallModal: {
      title: 'Avsluta utnämningen',
      paragraph: 'Har du redan gjort ditt möte? Klicka i så fall på avsluta samtalet och fyll i rapporten. Om inte klickar du på Lämna samtalet så kan du återgå till samtalet.',
      leaveCall: 'Lämna samtalet',
      endCall: 'Avsluta samtalet',
    },
    videoAccessModal: {
      title: 'Kameran eller mikrofonen är blockerad',
      description_1: 'Allbry kräver åtkomst till din kamera och mikrofon. Klicka på ikonen för blockerad kamera',
      description_2: 'i webbläsarens adressfält.',
      leaveCall: 'Lämna samtalet',
    },
  },
  chat: {
    end: 'Avsluta chatt',
    endInfoForCounsellor: 'Chatten avslutades kl {{time}} av dig. Skriv ett meddelande för att starta chattkonversationen på nytt. ',
    noMessages: 'Inga meddelanden än',
    sendFirstMessage: 'Skicka ditt första meddelande till {{person}}',
    writeMessage: 'Skriv ett meddelande...',
    endChatQuestion: 'Är du säker på att du vill avsluta chatten? Chatten kommer inte försvinna och du kan närsomhelst starta den igen genom att skicka ett nytt meddelande.',
    attachmentInfo: '{{fileSize}}, {{date}} kl {{time}}',
    sharedFiles: 'DELADE FILER',
    noFiles: 'Inga filer har delats än...',
    general: 'Generellt',
    messages: 'Meddelanden',
    chatEnded: '(Chatt avslutad)',
    attachment: '(Bilaga)',
    startTitle: 'Vem vill du chatta med?',
    noStudentsFound: 'Inga studenter hittades',
    noStudentsAvailable: 'Inga elever är tillgängliga',
    noStudentsAvailableToChat: 'Det finns inga tillgängliga elever att starta en chatt med',
    noCounsellorsAvailable: 'Inga rådgivare finns tillgängliga',
    noCounsellorsAvailableToChat: 'Det finns inga tillgängliga rådgivare att starta en chatt med',
    start: 'Starta chatt',
    more: 'Se mer',
    selectChat: 'Välj en chatt för att börja skicka meddelanden',
    archive: 'Arkivera',
    delete: 'Ta bort chatt',
    organizationDoesntWork: 'Allbry är inte öppet just nu. Du kan självklart fortfarande skicka ditt meddelande så svarar vi så fort vi kan. Vi har öppet och kurator finns på plats mellan {{start}} och {{end}}.',
    organizationDoesntWorkToday: 'Organisationen fungerar inte idag. Det kommer att ta längre tid att få svaret',
    endInfoForStudent: 'Chatten avslutades kl {{time}} av {{person}}. Du kan närsomhelst starta den igen genom att skicka ett nytt meddelande.',
    sendAfterWork: 'Skicka meddelande utanför Allbrys öppettider',
    workingHours: 'Allbry har öppet mellan {{start}} och {{end}}. Du kan självklart fortfarande skicka ditt meddelande så svarar vi så fort vi kan.',
    workingHoursToday: 'Organisationen fungerar inte idag. Om du vill skicka meddelande utanför arbetstid, observera att du får svar inom arbetstid.',
    ok: 'Jag förstår',
    deleteChatQuestion: 'Är du säker på att du vill ta bort chatten? ',
    archiveChat: 'Arkivera chatt',
    unarchiveChat: 'Återställ chatt',
    deleted: 'Chatten raderad',
    archived: 'Chatten arkiverad',
    unarchived: 'Chatten har återställts',
  },
  reports: {
    title: 'Snabbrapport',
    selectTopics: '1. Vilka ämnen har berörts under samtalet?',
    concernScale: '2. Hur orolig är du för eleven?',
    participationThanks: {
      title: 'Tack!',
      details: 'Tack för att du fyllt i snabbrapporten.Dessa används för att kunna få insikt i hur eleverna mår och vad dem pratar om för att göra en bra kartläggning av eleverna.',
      close: 'Stäng',
    },
    topics: {
      startOfSession: 'Sökt kontakt (startat samtal)',
      checkIn: 'Check-in',
      basicCare: 'Grundläggande omsorg',
      identity: 'Identitet',
      anxiety: 'Ångest',
      stress: 'Stress',
      physicalHealth: 'Fysisk hälsa',
      training: 'Träning',
      crime: 'Kriminalitet',
      sorrow: 'Sorg',
      socialRelationship: 'Sociala relationer',
      workLife: 'Arbetsliv',
      shame: 'Skam',
      honor: 'Heder',
      careFailure: 'Omsorgssvikt',
      economy: 'Ekonomi',
      security: 'Säkerhet',
      stimulationAndGuidance: 'Stimulans och vägledning',
      socialOrientation: 'Samhällsorientering',
      emotionalAvailability: 'Känslomässig tillgänglighet',
      love: 'Kärlek',
      mentalHealth: 'Psykisk hälsa',
      mentalAbuse: 'Psykisk misshandel',
      physicalAbuse: 'Fysisk misshandel',
      loveRelations: 'Kärleksrelationer',
      familyRelations: 'Familjerelationer',
      sexualAbuse: 'Sexuellt övergrepp',
      economicalAbuse: 'Ekonomisk misshandel',
      drugUse: 'Droganvändning',
      performanceAnxiety: 'Prestationsångest',
      loneliness: 'Ofrivillig ensamhet',
      grooming: 'Grooming',
    },
  },
  principal: {
    validation: {
      notEmpty: 'Värdet kan inte vara tomt',
      noSpaces: 'Värdet kan inte ha mellanslag',
      lessThan30Symbols: '{{value}} bör vara mindre än 30 symboler',
      exists: 'Attributet finns redan1',
      ageLessThan8: 'Din ålder får inte vara lägre än 8 år',
      ageLessThan18: 'Din ålder får inte vara lägre än 18 år',
      required: '{{value}} krävs',
      requiredName: 'Namn krävs',
      requiredEmail: 'E-postadress krävs',
      notValidEmail: 'Inte giltigt e-postadress',
    },
    addCustomField: 'Lägg till ett anpassat fält',
    overview: '{{name}} Översikt',
    schedule: 'Organisation schema',
    addCustomAttributes: 'Lägg till anpassade attribut',
    customFieldsDescription: 'När du skapar användaren ser du att anpassade fält har lagts till.',
    anonymous: 'Eleverna i din organisation får vara anonyma. Deras namn och profilbilder kommer att vara dolda för andra användare.',
    removeChatTitle: 'Ta bort chattar',
    removeChatDescription: 'Elever i din organisation får ta bort chattar med rådgivare.',
    archiveChatTitle: 'Arkivera chatten',
    archiveChatDescription: 'Eleverna i din organisation får arkivera chattar med rådgivare.',
    to: 'Till',
    from: 'Från',
    workingHours: 'Arbetstid',
    custom: 'Anpassad',
    weekDays: 'Veckodagar',
    allDays: 'Alla dagar',
    days: {
      monday: 'Måndag',
      tuesday: 'Tisdag',
      wednesday: 'Onsdag',
      thursday: 'Torsdag',
      friday: 'Fredag',
      saturday: 'Lördag',
      sunday: 'Söndag',
    },
    startLaterThanEnd: 'Starttiden är senare än sluttiden',
    endBeforeStart: 'Sluttiden är före starttiden',
    edit: 'Redigera användaren',
    add: 'Lägg till användaren',
    student: 'Studerande',
    counsellor: 'Rådgivare',
    allbryCounsellor: 'Allbry Rådgivare',
    userCreated: 'Användaren skapades',
    errorCreatingUser: 'Fel vid skapande av användaren',
    userUpdated: 'Användaren har uppdaterats',
    errorUpdatingUser: 'Fel vid uppdatering av användaren',
    userTable: {
      viewProfile: 'Visa profil',
      editProfile: 'Redigera profil',
      users: 'användare',
      active: 'Aktiv',
      inactive: 'Inaktiv',
      deactivated: 'Inaktiverad',
      nameSurname: 'Namn Efternamn',
      birthday: 'Födelsedatum',
      activate: 'Aktivera',
      deactivate: 'Inaktivera',
      profileActivated: 'Profil aktiverad',
      profileDeactivated: 'Profilen är inaktiverad',
      activatingError: 'Fel vid aktivering av profil',
      deactivatingError: 'Fel vid avaktivering av profil',
      generateCsvTemplate: 'Generera csv-mall',
      generateXlsxTemplate: 'Generera xlsx-mall',
      importUsers: 'Importera användare från xls eller xlsx',
      csvImportTitle: 'Import pågår',
      csvImportBody: 'Det kan ta lite tid att importera användare. Stäng inte fönstret',
      importButton: 'Importera',
      selectFiles: 'Välj den fil som ska importeras',
      importTitle: 'Importera användare',
      usersAddSuccess: 'Användare har lagts till',
      usersAddError: 'Fel vid import av användare',
      wrongFormat: 'Filformatet ska vara xls eller xlsx',
      wrongImportData: 'Fel i rad {{row}}! Kontrollera filen',
    },
    csv: {
      custom: 'Fyll i ett anpassat attribut',
      email: 'Fyll i personens e-postadress',
      role: 'Fyllnadsnummer: 1 - studerande, 2 - rådgivare',
      gender: 'Fyllnadsnummer: 1 - man, 2 - kvinna, 3 - annan',
      year: 'Födelseår',
      month: 'Födelsemånad (nummer)',
      day: 'Födelsedag',
      nameSurname: 'Fyll i personens namn och efternamn',
    },
    usersOrganization: '{{name}} Användare',
    addUserButton: 'Lägg till användare',
    about: 'Om mig',
    user: {
      role: 'Roll',
      email: 'E-postadress',
      status: 'Status',
    },
    enterFieldName: 'Ange fältnamn (t.ex. klass)',
    result: '{{value}} resultat',
    results: '{{value}} resultat',
    noResults: 'Inga resultat',
    day: 'Dag',
    month: 'Månad',
    year: 'År',
    statistic: {
      title: 'Statistik',
      calendar: 'Kalender',
      today: 'Idag',
      thisWeek: 'Denna vecka',
      thisMonth: 'Denna månad',
      thisQuarter: 'Detta kvartal',
      thisYear: 'Detta år',
      userTypes: 'Användartyper',
      notFound: 'Ingen statistik hittades',
      topics: 'Ämnen',
      experience: 'Erfarenhet',
      counselling: 'Rådgivning',
      usersAmount: '{{amount}} användare',
      levelOfWorry: 'Nivå av oro',
      chats: 'Chattar',
      appointments: 'Samtal',
      all: 'Alla',
      allTitle: {
        users: '{{value}} användare',
        user: '{{value}} användaren',
        times: '{{value}} gånger',
        time: '{{value}} tid',
      },
      registered: 'Registered',
      inactive: 'Inaktiv',
      todayInterval: 'Idag {{start}} - {{end}}',
      pdfExport: 'Exportera som PDF',
    },
  },
  admin: {
    title: 'Allbry Admin',
    modals: {
      addCounsellors: {
        choose: 'Välj rådgivare',
        search: 'Sök rådgivare',
      },
    },
    organization: {
      organizations: 'organisationer',
      edit: 'Redigera organisationen',
      details: 'Uppgifter om organisationen',
      closed: 'Stängt',
      start: 'Startdatum',
      end: 'Slutdatum',
      principal: 'Huvudansvarig',
      principals: 'Huvudmännen',
      create: 'Skapa en organisationen',
      view: 'Visa',
      editPopup: 'Redigera',
      deactivated: 'Organisationen inaktiverad',
      deactivatedError: 'Fel vid avaktivering av organisationen',
      name: 'Organisationens namn',
      startEnd: 'Startdatum/Slutdatum',
      addPrincipal: '+ Lägg till Huvudansvarig',
      addCounsellor: '+ Lägg till Rådgivare',
      searchOrganizations: 'Sök organisationer efter namn',
      edited: 'Organisationen redigerad',
      created: 'Organisation skapad',
      createdError: 'Fel i att skapa en organisation',
      editError: 'Fel i redigeringen av organisationen',
      longName: 'För långt namn',
      startDateRequired: 'Startdatum krävs',
      endBeforeStart: 'Slutdatum kan inte vara före startdatum',
      longEmail: 'För långt e-postadress',
      emailFormat: 'Fel e-postformat',
      duplicateEmails: 'Dubbla e-postmeddelanden',
      userExists: 'Användaren finns redan',
      atLeastOne: 'Minst en av dem måste vara aktiv.',
    },
    counsellors: {
      title: 'Rådgivare i Allbry',
      add: 'Add counsellor',
      edit: 'Redigera rådgivare',
      profile: 'Rådgivarens profil',
      searchCounsellors: 'Sök rådgivare efter namn',
    },
  },
  allbryCounsellor: {
    choose: 'Välj organisation',
    notFound: 'Ingen organisation hittad...',
    text: 'Du har inte lagts till i någon organisation ännu.',
    error: 'Fel när du försöker logga in i organisationen',
  },
  filterModal: {
    filter: 'Filter',
    addFilter: 'Lägg till filter',
    description: 'Visa användare som uppfyller alla villkor:',
    addCondition: '+ Lägg till villkor',
    is: 'är',
    isNot: 'är inte',
    chooseFilter: 'Välj filter',
    conditionValue: 'Ange villkorets värde',
  },
};

export default sv;
