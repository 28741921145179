import {
  createContext, useCallback, useEffect, useState,
} from 'react';
import AuthService from '@services/api/auth';
import LocalStorageService from '@services/localStorage';
import ProfileService from '@services/api/profile';
import {USER_ROLES, MEDIA_FONT_SIZE} from '@utils/consts';
import organizationService from '@services/api/organization';

export const AdminContext = createContext({
  user: null,
  organization: null,
  isMainLoading: false,
  htmlFontSize: null,
  login: async () => {
  },
  logout: async () => {
  },
  setHtmlFontSize: () => {},
});

const AdminProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const [htmlFontSize, setHtmlFontSize] = useState(MEDIA_FONT_SIZE.BASE);

  useEffect(() => {
    (async () => {
      setIsMainLoading(true);

      const accessToken = LocalStorageService.getAccessToken();
      const refreshToken = LocalStorageService.getRefreshToken();

      if (!accessToken && !refreshToken) {
        setIsMainLoading(false);
        return;
      }

      try {
        const userResponse = await AuthService.getCurrentUser();

        const organizationData = await organizationService.getOrganizations(userResponse.accountId);
        if (!organizationData[0]) {
          throw new Error();
        }

        setOrganization(organizationData[0]);
        setUser(userResponse);
      } catch (err) {
        LocalStorageService.removeAccessToken();
        LocalStorageService.removeRefreshToken();
      } finally {
        setIsMainLoading(false);
      }
    })();
  }, []);

  const login = useCallback(async (email, password) => {
    const result = await AuthService.login(email, password);
    if (!result.accessToken) {
      return false;
    }

    LocalStorageService.setAccessToken(result.accessToken);
    LocalStorageService.setRefreshToken(result.refreshToken);

    const userResponse = await AuthService.getCurrentUser();

    const profileResponse = await ProfileService.getProfileByUserId(
      userResponse.accountId, userResponse.id,
    );

    if (profileResponse?.type !== USER_ROLES.ADMIN_ROLE) {
      LocalStorageService.removeAccessToken();
      LocalStorageService.removeRefreshToken();
      throw new Error();
    }

    const organizationResponse = await organizationService.getOrganizationById(
      userResponse.accountId, profileResponse.organizationId,
    );

    setOrganization(organizationResponse);
    setUser(userResponse);

    return true;
  }, []);

  const logout = useCallback(async () => {
    await AuthService.logout();

    LocalStorageService.removeAccessToken();
    LocalStorageService.removeRefreshToken();

    setUser(null);
    setOrganization(null);
  }, []);

  const value = {
    user,
    organization,
    login,
    logout,
    isMainLoading,
    htmlFontSize,
    setHtmlFontSize,
  };

  return (
    <AdminContext.Provider value={{...value}}>{children}</AdminContext.Provider>
  );
};

export default AdminProvider;
