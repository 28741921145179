import config from '@config/config';
import ApiService from './api';

class DistributedUsersApi extends ApiService {
  constructor() {
    if (DistributedUsersApi.instance) {
      return DistributedUsersApi.instance;
    }

    super(config.authApiUrl);
    DistributedUsersApi.instance = this;
  }

  async getDistributedUsers({userId, distributedAccountId}) {
    const userIdParam = userId ? `userId=${userId}` : null;
    const distributedAccountIdParam = distributedAccountId ? `distributedAccountId=${distributedAccountId}` : null;

    const response = await this.http.get(`/distributedUsers${userIdParam || distributedAccountIdParam
      ? `?${userIdParam || ''}${distributedAccountIdParam ? `${userIdParam ? '&' : ''}${distributedAccountIdParam}` : ''}`
      : ''}`);

    return response.data;
  }

  async createDistributedUsers(data) {
    const response = await this.http.post('/distributedUsers', data);
    return response.data;
  }

  async updateDistributedUsers(distributedUserId, data) {
    const response = await this.http.put(`/distributedUsers/${distributedUserId}`, data);
    return response.data;
  }
}

const distributedUsersApiInstance = new DistributedUsersApi();
export default distributedUsersApiInstance;
