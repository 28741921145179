export const USER_ROLES = {
  PRINCIPAL_ROLE: 'principal',
  COUNSELLOR_ROLE: 'counsellor',
  STUDENT_ROLE: 'student',
  ADMIN_ROLE: 'admin',
  ALLBRY_COUNSELLOR_ROLE: 'allbryCounsellor',
};

export const SURVEY_STATUSES = {
  STUDENT_SURVEY: 'studentSurvey',
};

export const AFTER_SURVEY_STEPS = {
  FIRST_STEP: 'appointments',
  SECOND_STEP: 'messages',
};

export const CHAT_MESSAGE_TYPES = {
  TEXT: 'text',
  ATTACHMENT: 'attachment',
  TEXT_ATTACHMENT: 'textAttachment',
  END: 'end',
};

export const millisecondsInHour = 3600000;

export const NOTE_TYPES = {
  MANUAL: 'manual',
  APPOINTMENT: 'appointment',
  META: 'meta',
};

export const GENDERS = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const USER_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DEACTIVATED: 'deactivated',
};

export const ORGANIZATION_STATUSES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

export const FREE_TIME_REPEAT_TYPES = {
  NONE: 'none',
  EVERYDAY: 'everyday',
  WEEKLY: 'weekly',
  EVERY_WEEKDAY: 'every_weekday',
};

export const MEDIA_FONT_SIZE = {
  XS: 12,
  SM: 14,
  BASE: 16,
};

export const MEDIA_SCREEN = {
  SM: 1280,
  LG: 1601,
};
