import * as React from 'react';
import clsx from 'clsx';

function CloseIcon({
  color, className, ...props
}) {
  return (
    <svg
      className={clsx('w-5 h-5', className)}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.666 1.668l9.333 9.333m0 0l9.334 9.334M10.999 11l9.334-9.333m-9.334 9.333l-9.333 9.334"
        stroke={color || '#9F9F9F'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloseIcon;
