import {useState, useEffect, useRef} from 'react';
import clsx from 'clsx';

import {Chat} from '@assets/icons';
import {useChatList} from '@hooks/chats';
import messageSound from '../../../assets/sounds/Message.mp3';

const ChatIcon = ({isActiveTab, className, ...props}) => {
  const isMounted = useRef(false);
  const soundRef = useRef(new Audio(messageSound));
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(null);
  const [chatList] = useChatList({
    isPopulateChatParticipants: true,
    isPopulateLastMessage: true,
    isPopulateChatParticipantProfiles: false,
    allowReadMessageEventEmitting: false,
    isCacheEnabled: false,
  });

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const result = chatList.map((chatRoom) => chatRoom.unreadMessageCount).reduce((prev, cur) => prev + cur, 0);

    if (unreadMessagesCount !== null && result > unreadMessagesCount) {
      soundRef.current?.play().catch(() => {});
    }

    setUnreadMessagesCount(result);
  }, [chatList]);

  return (
    <div className="relative">
      {unreadMessagesCount && unreadMessagesCount > 0
        ? (
          <div className={clsx(
            'absolute -right-2/5 -top-35% h-6 w-6 bg-primary rounded-full flex items-center justify-center border-1.5',
            isActiveTab ? 'border-pampas' : 'border-main',
          )}
          >
            <span className="text-white font-light-rubik font-black text-sm leading-5">
              {unreadMessagesCount}
            </span>
          </div>
        )
        : null}
      <Chat className={className} {...props} />
    </div>
  );
};

export default ChatIcon;
