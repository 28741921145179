import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';

const Counsellor = {
  Appointments: loadable(() => import('./Appointments'), loadableRootParams),
  AppointmentDetail: loadable(() => import('./AppointmentDetail'), loadableRootParams),
  Messages: loadable(() => import('./Messages'), loadableRootParams),
  StudentNotesList: loadable(() => import('./Notes/NotesList/NotesList'), loadableRootParams),
  StudentNotesAdd: loadable(() => import('./Notes/NoteAdd/NoteAdd'), loadableRootParams),
  StudentNotesItem: loadable(() => import('./Notes/NoteItem/NoteOverview'), loadableRootParams),
  Booking: loadable(() => import('./AppointmentBooking'), loadableRootParams),
  FreeTime: loadable(() => import('./FreeTime'), loadableRootParams),
};

export default Counsellor;
