import config from '@config/config';
import ApiService from './api';

class OrganizationApi extends ApiService {
  constructor() {
    if (OrganizationApi.instance) {
      return OrganizationApi.instance;
    }

    super(config.accountApiUrl);
    OrganizationApi.instance = this;
  }

  async createOrganization(accountId, data) {
    const response = await this.http.post(`/accounts/${accountId}/organizations/`, data);
    return response.data;
  }

  async getOrganizations(accountId) {
    const response = await this.http.get(`/accounts/${accountId}/organizations`);
    return response.data;
  }

  async getOrganizationById(accountId, id) {
    const response = await this.http.get(`/accounts/${accountId}/organizations/${id}`);
    return response.data;
  }

  async updateOrganization(accountId, id, data) {
    const response = await this.http.put(`/accounts/${accountId}/organizations/${id}`, data);
    return response.data;
  }

  async getAttributes(accountId, id) {
    const response = await this.http.get(`/accounts/${accountId}/organizations/${id}/personAttributeConfigs`);
    return response.data;
  }

  async removeAttribute(accountId, id, atrId) {
    const response = await this.http.delete(`/accounts/${accountId}/organizations/${id}/personAttributeConfigs/${atrId}`);
    return response.data;
  }

  async addAttribute(accountId, id, data) {
    const response = await this.http.post(`/accounts/${accountId}/organizations/${id}/personAttributeConfigs`, data);
    return response.data;
  }
}

const organizationApiInstance = new OrganizationApi();
export default organizationApiInstance;
