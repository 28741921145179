import {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
import clsx from 'clsx';

import styles from './Slider.module.scss';

const Slider = ({
  className, containerClassName, value = 0, min = 0, max = 10, step = 0.5, onChange,
}) => {
  const [valueWidth, setValueWidth] = useState(0);
  const sliderWidth = useRef(null);

  const steps = useMemo(() => {
    const comps = [];
    for (let i = min; i <= max; i += step) {
      comps.push(
        ({style}) => <div className="w-4 text-center flex justify-center items-center" style={style} key={i}>{i}</div>,
      );
    }

    return comps;
  }, [min, max, step]);

  const recalculateSize = useCallback(() => {
    setValueWidth(((max - value) / (max - min)) * sliderWidth.current.offsetWidth);
  }, [max, min, value]);

  useEffect(() => {
    window.addEventListener('resize', recalculateSize);

    return () => {
      window.removeEventListener('resize', recalculateSize);
    };
  }, [recalculateSize]);

  useEffect(() => {
    if (!sliderWidth.current) {
      return;
    }

    setValueWidth(((max - value) / (max - min)) * sliderWidth.current.offsetWidth);
  }, [value]);

  return (
    <div className={clsx('w-full overflow-visible', styles.sliderInputContainer, containerClassName)}>
      <div className="flex items-center relative min-h-12" ref={sliderWidth}>
        <div className={clsx(styles.backgroundLeftLayer)} />
        <div className={clsx(styles.backgroundRightLayer)} style={{width: valueWidth}} />
        <input type="range" className={clsx(styles.sliderInput, 'appearance-none overflow-visible', className)} min={min} max={max} step={step} value={value} onChange={onChange} />
      </div>
      <div className="flex flex-row justify-between px-3 mt-2">
        {steps.map((Step, index) => (
          <Step
            key={index} // eslint-disable-line react/no-array-index-key
            style={{
              color: (index * step).toString() === value ? '#585550' : '#cececc',
              fontSize: (index * step).toString() === value ? 16 : 14,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
