import * as React from 'react';
import clsx from 'clsx';

const BoldCloseIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-3.5 h-3.5', className)}
    fill="none"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.168 1.168 5.833 5.833m0 0 5.834 5.834M7 7l5.834-5.833M7 7.001l-5.833 5.834"
      stroke="#585550"
      strokeOpacity={0.5}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BoldCloseIcon;
