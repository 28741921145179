import {v4 as uuid} from 'uuid';

export const setFieldForm = (method, setErrors, nameField, val) => {
  method(nameField, val, false);
  setErrors({});
};

export const checkErrorsBySubmit = (errors, fields) => !!Object.keys(errors).length
  || Object.values(fields).includes('');

export const generateUUID = () => uuid();

export const colorByPercent = (value) => {
  if (value > 60) {
    return increaseBrightness('#FAC783', -value);
  }
  if (value > 40) {
    return increaseBrightness('#83B2F0', value);
  }
  if (value > 20) {
    return increaseBrightness('#94CB8C', value);
  }
  if (value > 10) {
    return increaseBrightness('#D1C7BB', -value);
  }
  return increaseBrightness('#E1E1E1', -value);
};

export const increaseBrightness = (hex, percent) => {
  // strip the leading # if it's there
  let color = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (color.length === 3) {
    color = hex.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  return `#${
    ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1)}`;
};

export const sumAllValuesInArr = (items, prop) => items.reduce((a, b) => a + Number(b[prop] ?? 0), 0);

export const getMeanValue = (nums) => nums.reduce((a, b) => (a + b)) / nums.length;

export const INTERVAL_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
};

export const formatByType = {
  [INTERVAL_TYPES.DAY]: 'HH',
  [INTERVAL_TYPES.WEEK]: 'EEE',
  [INTERVAL_TYPES.MONTH]: 'dd',
  [INTERVAL_TYPES.QUARTER]: 'MMM',
  [INTERVAL_TYPES.YEAR]: 'MMM',
};

export const handleNameLength = (name, trimValue = 15) => {
  if (name && name.length > trimValue) {
    return `${name.substring(0, trimValue)}...`;
  }
  return name;
};
