import config from '@config/config';
import ApiService from './api';

class ReportApi extends ApiService {
  constructor() {
    if (ReportApi.instance) {
      return ReportApi.instance;
    }

    super(config.reportApiUrl);
    ReportApi.instance = this;
  }

  async createReport(accountId, report) {
    const response = await this.http.post(`/accounts/${accountId}/reports`, report);
    return response.data;
  }

  async getWorries(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/worryLevel${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }

  async getTopics(accountId, dtStart, dtEnd) {
    const response = await this.http.get(`/accounts/${accountId}/statistics/topics${dtStart ? `?dtStart=${dtStart}&dtEnd=${dtEnd}` : ''}`);
    return response.data;
  }
}

const reportApiInstance = new ReportApi();
export default reportApiInstance;
