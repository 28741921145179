import {renderRoutes} from 'react-router-config';
import {BaseLink} from '@components/Form';
import {Logo} from '@assets/icons';
import React from 'react';
import {ADMIN_ROOT} from '@admin/router/consts';

const AuthAdmin = ({route}) => (
  <div className="bg-main min-h-screen flex justify-center items-center relative">
    <BaseLink to={ADMIN_ROOT} className="absolute top-10 left-10 pointer-events-none">
      <Logo />
    </BaseLink>
    {renderRoutes(route.routes)}
  </div>
);
export default AuthAdmin;
