import {
  APPOINTMENTS,
  MESSAGES,
  PROFILE_STUDENT_VIEW_NOTE,
  PROFILE_STUDENT_VIEW_NOTES,
  PROFILE_STUDENT_VIEW_NOTES_ADD,
  APPOINTMENT_DETAIL,
  APPOINTMENTS_BOOKING,
  APPOINTMENTS_FREE_TIME,
} from '@router/consts';
import {Calendar} from '@assets/icons';
import Chat from '@components/Navbar/ChatIcon';
import Counsellor from '@pages/Counsellor';

export const COUNSELLOR_MENU_ROUTES = [
  {
    name: 'appointments',
    path: APPOINTMENTS,
    icon: Calendar,
    exact: true,
    isMenu: true,
    component: Counsellor.Appointments,
  },
  {
    name: 'messages',
    exact: true,
    isMenu: true,
    icon: Chat,
    path: MESSAGES,
    component: Counsellor.Messages,
  },
];

export const COUNSELLOR_ROUTES = [
  ...COUNSELLOR_MENU_ROUTES,
  {
    name: 'appointmentBooking',
    exact: true,
    path: APPOINTMENTS_BOOKING,
    component: Counsellor.Booking,
  },
  {
    name: 'appointmentFreeTime',
    exact: true,
    path: APPOINTMENTS_FREE_TIME,
    component: Counsellor.FreeTime,
  },
  {
    name: 'appointmentDetail',
    path: APPOINTMENT_DETAIL,
    component: Counsellor.AppointmentDetail,
  },
  {
    name: 'studentNotes',
    path: PROFILE_STUDENT_VIEW_NOTES,
    exact: true,
    component: Counsellor.StudentNotesList,
  },
  {
    name: 'studentNoteAdd',
    path: PROFILE_STUDENT_VIEW_NOTES_ADD,
    exact: true,
    component: Counsellor.StudentNotesAdd,
  },
  {
    name: 'studentNoteItem',
    path: PROFILE_STUDENT_VIEW_NOTE,
    component: Counsellor.StudentNotesItem,
  },
];
