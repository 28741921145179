import {useMemo} from 'react';
import {REDIRECT} from '@admin/router/consts';
import RootAdmin from '@admin/components/Layout/RootAdmin';
import {ADMIN_AUTH_ROUTES, ADMIN_ROOT_ROUTES} from '@admin/router';
import AuthAdmin from '@admin/components/Layout/AuthAdmin';
import Redirect from '@admin/pages/RedirectPage';

export const useAdminRoutes = (user, isMainLoading) => useMemo(() => {
  if (isMainLoading) return [];

  if (user) {
    return [{
      component: RootAdmin,
      routes: [
        ...ADMIN_ROOT_ROUTES,
        {
          path: REDIRECT,
          component: Redirect,
        },
      ],
    }];
  }

  return [{
    component: AuthAdmin,
    routes: [
      ...ADMIN_AUTH_ROUTES,
      {
        path: REDIRECT,
        component: Redirect,
      },
    ],
  }];
}, [user, isMainLoading]);
