import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ChatMessage = ({
  className, name, message, time, variant,
}) => (
  <div className={clsx('flex flex-col p-4 pb-6 rounded-2xl relative', className)}>
    <p className="font-medium-rubik text-xl text-dawn-gray mb-2">{name}</p>
    <p className={clsx(
      'text-lg text-dawn-gray',
      // eslint-disable-next-line no-nested-ternary
      variant === 'second'
        ? 'max-w-50'
        : variant === 'third'
          ? 'max-w-20'
          : '',
      variant === 'fourth'
        ? 'max-w-70'
        : '',
    )}
    >
      {message}
    </p>
    <p className="absolute text-dawn-gray text-opacity-70 text-xs bottom-3 right-3">{time}</p>
  </div>
);

ChatMessage.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  time: PropTypes.string,
  variant: PropTypes.string,
};

ChatMessage.defaultProps = {
  name: '',
  message: '',
  time: '',
  variant: '',
};

export default ChatMessage;
