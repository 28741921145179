import config from '@config/config';
import {addHours} from 'date-fns';
import ApiService from './api';

class VideoApi extends ApiService {
  constructor() {
    if (VideoApi.instance) {
      return VideoApi.instance;
    }

    super(config.videoApiUrl);
    VideoApi.instance = this;
  }

  async joinRoom(accountId, roomId, options = {}) {
    const includeParticipantsQuery = options?.includeParticipants ? '?includeParticipants=true' : '';
    const response = await this.http.post(`/accounts/${accountId}/rooms/${roomId}/join${includeParticipantsQuery}`);

    return response.data;
  }

  async createRoom(appointment) {
    const participants = appointment.originalParticipants.map((participant) => ({
      userId: participant.userId,
    }));

    const response = await this.http.post(`/accounts/${appointment.accountId}/rooms`, {
      accountId: appointment.accountId,
      userId: appointment.organizerId,
      eventId: appointment.globalCalendarEventId,
      expiresAt: addHours(Date.now(), 2).getTime(), // default room expiration time
      participants,
    });

    return response.data;
  }

  async updateRoom(room) {
    const response = await this.http.put(`/accounts/${room.accountId}/rooms/${room.id}`, {
      ...room,
    });

    return response.data;
  }

  async getRoomById(accountId, roomId, options = {}) {
    const includeParticipantsQuery = options?.includeParticipants ? '?includeParticipants=true' : '';
    const response = await this.http.get(`/accounts/${accountId}/rooms/${roomId}${includeParticipantsQuery}`);

    return response.data;
  }
}

const videoApiInstance = new VideoApi();
export default videoApiInstance;
