import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';

const Root = {
  Organizations: loadable(async () => import('./Organizations'), loadableRootParams),
  Organization: loadable(async () => import('./Organization'), loadableRootParams),
  OrganizationManagement: loadable(async () => import('./OrganizationManagement'), loadableRootParams),
  Settings: loadable(async () => import('./Settings'), loadableRootParams),
  Counsellors: loadable(async () => import('./allbryCounsellors'), loadableRootParams),
  CounsellorView: loadable(async () => import('./allbryCounsellorView'), loadableRootParams),
  CounsellorsManagement: loadable(async () => import('./allbryCounsellorsManagement'), loadableRootParams),
};

export default Root;
