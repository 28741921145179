import config from '@config/config';
import ApiService from './api';

class ResourceApi extends ApiService {
  constructor() {
    if (ResourceApi.instance) {
      return ResourceApi.instance;
    }

    super(config.resourceApiUrl);
    ResourceApi.instance = this;
  }

  async uploadFile(accountId, formData) {
    const response = await this.http.post(`/accounts/${accountId}/upload`, formData);
    return response.data;
  }

  async createResource(accountId, data) {
    const response = await this.http.post(`/accounts/${accountId}/resources`, data);
    return response.data;
  }

  async getResourcesByEntity(accountId, entityId, entityType) {
    const response = await this.http.get(`/accounts/${accountId}/resources?entityId=${entityId}&entityType=${entityType}`);
    return response.data;
  }
}

const resourceApiInstance = new ResourceApi();
export default resourceApiInstance;
