import loadable from '@loadable/component';
import {loadableAuthParams} from '@components/Skeleton/loadableParams';

const Auth = {
  Welcome: loadable(async () => import('./Welcome'), loadableAuthParams),
  SignIn: loadable(() => import('./SignIn'), loadableAuthParams),
  SignUp: loadable(() => import('./SignUp'), loadableAuthParams),
  Forgot: loadable(() => import('./Forgot'), loadableAuthParams),
  Sso: loadable(() => import('./Sso'), loadableAuthParams),
};

export default Auth;
