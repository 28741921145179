import Auth from '@admin/pages/Auth';
import Root from '@admin/pages/Root';
import {ManyUsers, SettingsNavbar, User} from '@assets/icons';
import {
  ADMIN_HOME,
  ADMIN_SETTINGS,
  ADMIN_SIGN_IN,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  ADMIN_COUNSELLORS,
  ADMIN_ADD_COUNSELLORS,
  ADMIN_VIEW_COUNSELLOR,
  ADMIN_EDIT_COUNSELLORS,
} from './consts';

export const ADMIN_AUTH_ROUTES = [
  {
    name: 'signIn',
    path: ADMIN_SIGN_IN,
    exact: true,
    component: Auth.SignIn,
  },
];

export const ADMIN_ROOT_ROUTES = [
  {
    name: 'organizations',
    path: ADMIN_HOME,
    icon: ManyUsers,
    exact: true,
    isMenu: true,
    component: Root.Organizations,
  },
  {
    name: 'addOrganization',
    path: ADD_ORGANIZATION,
    exact: true,
    component: Root.OrganizationManagement,
  },
  {
    name: 'editOrganization',
    path: EDIT_ORGANIZATION,
    exact: true,
    component: Root.OrganizationManagement,
  },
  {
    name: 'viewOrganization',
    path: `${ADMIN_HOME}/:id`,
    exact: true,
    component: Root.Organization,
  },
  {
    name: 'counsellors',
    path: ADMIN_COUNSELLORS,
    exact: true,
    isMenu: true,
    icon: User,
    component: Root.Counsellors,
  },
  {
    name: 'settings',
    path: ADMIN_SETTINGS,
    exact: true,
    isMenu: true,
    icon: SettingsNavbar,
    component: Root.Settings,
  },
  {
    name: 'addCounsellor',
    path: ADMIN_ADD_COUNSELLORS,
    exact: true,
    component: Root.CounsellorsManagement,
  },
  {
    name: 'viewCounsellor',
    path: ADMIN_VIEW_COUNSELLOR,
    exact: true,
    component: Root.CounsellorView,
  },
  {
    name: 'editCounsellor',
    path: ADMIN_EDIT_COUNSELLORS,
    exact: true,
    component: Root.CounsellorsManagement,
  },
];
