import * as React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function CalendarIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-8 h-7', className)}
      fill="none"
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.641 1.026h-.282V.769c0-.424-.38-.769-.846-.769-.467 0-.846.345-.846.77v.256H7.333V.769C7.333.345 6.954 0 6.487 0c-.467 0-.846.345-.846.77v.256h-.282C2.404 1.026 0 3.21 0 5.897v9.231C0 17.814 2.404 20 5.359 20h11.282C19.596 20 22 17.814 22 15.128v-9.23c0-2.687-2.404-4.872-5.359-4.872zM5.359 2.564h.282v1.282c0 .425.38.77.846.77.467 0 .846-.345.846-.77V2.564h7.334v1.282c0 .425.379.77.846.77.467 0 .846-.345.846-.77V2.564h.282c2.022 0 3.667 1.495 3.667 3.333v.257H1.692v-.257c0-1.838 1.645-3.333 3.667-3.333zm11.282 15.898H5.359c-2.022 0-3.667-1.496-3.667-3.334V7.692h18.616v7.436c0 1.838-1.645 3.334-3.667 3.334zm0-7.436c0 .566-.505 1.025-1.128 1.025-.623 0-1.128-.46-1.128-1.025 0-.566.505-1.026 1.128-1.026.623 0 1.128.46 1.128 1.026zm-4.513 0c0 .566-.505 1.025-1.128 1.025-.623 0-1.128-.46-1.128-1.025C9.872 10.46 10.377 10 11 10c.623 0 1.128.46 1.128 1.026zm-4.513 0c0 .566-.505 1.025-1.128 1.025-.623 0-1.128-.46-1.128-1.025 0-.566.505-1.026 1.128-1.026.623 0 1.128.46 1.128 1.026zm9.026 4.102c0 .566-.505 1.026-1.128 1.026-.623 0-1.128-.46-1.128-1.026 0-.566.505-1.025 1.128-1.025.623 0 1.128.46 1.128 1.025zm-4.513 0c0 .566-.505 1.026-1.128 1.026-.623 0-1.128-.46-1.128-1.026 0-.566.505-1.025 1.128-1.025.623 0 1.128.46 1.128 1.025zm-4.513 0c0 .566-.505 1.026-1.128 1.026-.623 0-1.128-.46-1.128-1.026 0-.566.505-1.025 1.128-1.025.623 0 1.128.46 1.128 1.025z"
      />
    </svg>
  );
}

CalendarIcon.propTypes = {
  className: PropTypes.string,
};

CalendarIcon.defaultProps = {
  className: 'fill-current text-dawn-gray',
};

export default CalendarIcon;
