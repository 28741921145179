import clsx from 'clsx';

function PaperClipIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-5 h-5', className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 23"
      {...props}
    >
      <path
        d="M18.762 13.888l-5.454 5.689A7.86 7.86 0 017.67 22c-2.072 0-4-.822-5.433-2.316-3.022-3.152-2.977-8.33.103-11.542l6.213-6.48C9.581.59 10.948 0 12.402 0c1.453 0 2.82.59 3.848 1.662 2.12 2.211 2.12 5.809 0 8.021l-6.242 6.511c-1.183 1.232-3.243 1.232-4.425 0-1.218-1.27-1.218-3.337 0-4.608l5.483-5.72a.793.793 0 011.151 0 .87.87 0 01.001 1.197L6.735 12.78a1.617 1.617 0 000 2.218 1.488 1.488 0 002.12 0l6.242-6.51c1.49-1.553 1.49-4.079 0-5.631-1.44-1.503-3.952-1.503-5.392 0l-6.213 6.48c-2.406 2.51-2.453 6.7-.103 9.151a5.874 5.874 0 004.28 1.819 6.253 6.253 0 004.486-1.927l5.454-5.689a.793.793 0 011.151 0 .87.87 0 01.002 1.196z"
        fill="#B8B8B8"
      />
    </svg>
  );
}

export default PaperClipIcon;
