import clsx from 'clsx';

function CheckBoxIcon({className, size = 'w-9 h-9', ...props}) {
  return (
    <svg
      className={clsx(size, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34 34"
      {...props}
    >
      <circle cx={17} cy={17} r={17} fill="#D1C7BB" />
      <path d="M23.263 11.633l-9.227 9.842L9.842 17" fill="#D1C7BB" />
      <path
        d="M23.263 11.633l-9.227 9.842L9.842 17"
        stroke="#fff"
        strokeWidth={2.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckBoxIcon;
