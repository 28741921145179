import config from '@config/config';
import ApiService from './api';

class CalendarApi extends ApiService {
  constructor() {
    if (CalendarApi.instance) {
      return CalendarApi.instance;
    }

    super(config.calendarApiUrl);
    CalendarApi.instance = this;
  }

  async getCalendarByUserId(accountId, userId) {
    const response = await this.http.get(`/accounts/${accountId}/calendars?ownerId=${userId}`);
    return response.data;
  }

  async getCalendarEventsByCalendarId({
    accountId,
    calendarId,
    dtStart = 0,
    dtEnd = 99999999999999,
    populateParticipants = false,
    participantUserIds,
    globalEventId,
  }) {
    const calendarIdParam = `calendarId=${calendarId}`;
    const dtStartParam = `dtStart=${dtStart}`;
    const dtEndParam = `dtEnd=${dtEnd}`;
    const populateParticipantsParam = `populateParticipants=${populateParticipants}`;
    const globalEventIdParam = globalEventId ? `globalEventId=${globalEventId}` : null;
    const participantsParam = participantUserIds
      ? participantUserIds.map((v) => `participant=${v}`).join('&')
      : null;

    const response = await this.http.get(`/accounts/${accountId}/calendarEvents?${calendarIdParam}&${dtStartParam}&${dtEndParam}&${populateParticipantsParam}${participantsParam
      ? `&${participantsParam}`
      : ''}${globalEventIdParam ? `&${globalEventIdParam}` : ''}`);

    return response.data;
  }

  async getCalendarEventById(accountId, calendarEventId, populateParticipants = false) {
    const response = await this.http.get(`/accounts/${accountId}/calendarEvents/${calendarEventId}${populateParticipants ? `?populateParticipants=${populateParticipants}` : ''}`);
    return response.data;
  }

  async createCalendarEvent(accountId, calendarEvent) {
    const response = await this.http.post(`/accounts/${accountId}/calendarEvents`, calendarEvent);
    return response.data;
  }

  async createCalendarEventParticipant(accountId, calendarEventParticipant) {
    const response = await this.http.post(`/accounts/${accountId}/calendarEvents/${calendarEventParticipant.eventId}/participants`, calendarEventParticipant);
    return response.data;
  }

  async updateCalendarEvent(accountId, calendarEvent) {
    const response = await this.http.put(`/accounts/${accountId}/calendarEvents/${calendarEvent.id}`, calendarEvent);
    return response.data;
  }

  async getCalendarEventsByOwnerId(accountId, ownerId, {dtStart, dtEnd}, participantUserIds) {
    const participantsParam = participantUserIds
      ? participantUserIds.map((v) => `participant=${v}`).join('&')
      : null;

    const {id: calendarId} = await this.getCalendarByUserId(accountId, ownerId);

    const calendarIdParam = `calendarId=${calendarId}`;
    const dtStartParam = `dtStart=${dtStart}`;
    const dtEndParam = `dtEnd=${dtEnd}`;

    const eventsResponse = await this.http.get(`/accounts/${accountId}/calendarEvents?${calendarIdParam}&${dtStartParam}&${dtEndParam}${participantsParam ? `&${participantsParam}` : ''}`);
    return eventsResponse.data;
  }

  async createCalendar(accountId, data) {
    const response = await this.http.post(`/accounts/${accountId}/calendars`, data);
    return response.data;
  }

  async deleteCalendarEventById(accountId, id) {
    const response = await this.http.delete(`/accounts/${accountId}/calendarEvents/${id}`);
    return response.data;
  }
}

const calendarApiInstance = new CalendarApi();
export default calendarApiInstance;
