import {
  ADD_USER, EDIT_USER, ORGANIZATION, STATISTICS, USERS,
} from '@router/consts';
import Principal from '@pages/Principal';
import {ManyUsers, Statistics, User} from '@assets/icons';

export const PRINCIPAL_ROUTE_NAME = {
  ORGANIZATION: 'organization',
  USERS: 'users',
  STATISTICS: 'statistics',
  CHANGE_USER: 'changeUser',
};

export const PRINCIPAL_MENU_ROUTES = [
  {
    name: 'statistics',
    path: STATISTICS,
    exact: true,
    icon: Statistics,
    isMenu: true,
    component: Principal.Statistics,
  },
  {
    name: 'users',
    path: USERS,
    exact: true,
    icon: User,
    isMenu: true,
    component: Principal.Users,
  },
  {
    name: 'organization',
    path: ORGANIZATION,
    exact: true,
    icon: ManyUsers,
    isMenu: true,
    component: Principal.Organization,
  },
];

export const PRINCIPAL_ROUTES = [
  ...PRINCIPAL_MENU_ROUTES,
  {
    name: 'addUser',
    path: ADD_USER,
    exact: true,
    component: Principal.ChangeUser,
  },
  {
    name: 'editUser',
    path: EDIT_USER,
    exact: true,
    component: Principal.ChangeUser,
  },
  {
    name: 'profileUser',
    path: `${USERS}/:id`,
    exact: true,
    component: Principal.ProfileUser,
  },
];
