export const ROOT = '/';

export const AUTH_ROOT_SSO = `${ROOT}sso`;

export const AUTH_ROOT_SIGN_IN = `${ROOT}sign-in`;
export const AUTH_SIGN_IN_STEPS = {
  SIGN_IN_EMAIL: `${AUTH_ROOT_SIGN_IN}?email`,
  SIGN_IN_PASSWORD: `${AUTH_ROOT_SIGN_IN}?password`,
};

export const AUTH_ROOT_SIGN_UP = `${ROOT}sign-up`;
export const AUTH_SIGN_UP_STEPS = {
  SIGN_UP_CREATE: `${AUTH_ROOT_SIGN_UP}?create`,
  SIGN_UP_EMAIL: `${AUTH_ROOT_SIGN_UP}?email`,
};

export const AUTH_ROOT_FORGOT = `${ROOT}forgot`;
export const AUTH_FORGOT_STEPS = {
  FORGOT_CHECK: `${AUTH_ROOT_FORGOT}?check`,
  FORGOT_PASSWORD: `${AUTH_ROOT_FORGOT}?create`,
};

export const PROFILE = `${ROOT}profile`;

export const EDIT_PROFILE = `${PROFILE}/:id/edit`;
export const PROFILE_STUDENT_VIEW_NOTES = `${PROFILE}/:id/notes`;
export const PROFILE_STUDENT_VIEW_NOTES_ADD = `${PROFILE_STUDENT_VIEW_NOTES}/add`;
export const PROFILE_STUDENT_VIEW_NOTE = `${PROFILE_STUDENT_VIEW_NOTES}/:noteId`;

export const SETTINGS = `${ROOT}settings`;

export const SURVEY_ROOT = '/';

export const NOT_FOUND = '*';

export const APPOINTMENTS = `${ROOT}appointments`;
export const APPOINTMENT_DETAIL = `${ROOT}appointments/:id`;
export const APPOINTMENTS_BOOKING = `${APPOINTMENTS}/book`;
export const APPOINTMENTS_FREE_TIME = `${APPOINTMENTS}/free`;

export const COUNSELLORS = `${ROOT}counsellors`;

export const MESSAGES = `${ROOT}messages`;

export const VIDEO_ROOT = `${ROOT}meet`;
export const VIDEO_ROOM = `${VIDEO_ROOT}/:id`;

export const ORGANIZATION = `${ROOT}organization`;
export const STATISTICS = `${ROOT}statistics`;

export const USERS = `${ROOT}users`;
export const ADD_USER = `${USERS}/add`;
export const EDIT_USER = `${USERS}/:id/edit`;

export const ACCOUNT_SELECTION = `${ROOT}accountSelection`;
