import Auth from '@pages/Auth';
import {
  AUTH_ROOT_FORGOT, AUTH_ROOT_SIGN_IN, AUTH_ROOT_SIGN_UP, NOT_FOUND, ROOT, AUTH_ROOT_SSO,
} from '@router/consts';
import {RedirectSurvey} from '@pages/RedirectPage';

export const AUTH_ROUTES = [
  {
    name: 'signIn',
    path: AUTH_ROOT_SIGN_IN,
    exact: true,
    component: Auth.SignIn,
  },
  {
    name: 'signUp',
    path: AUTH_ROOT_SIGN_UP,
    exact: true,
    component: Auth.SignUp,
  },
  {
    name: 'forgot',
    path: AUTH_ROOT_FORGOT,
    exact: true,
    component: Auth.Forgot,
  },
  {
    name: 'sso',
    path: AUTH_ROOT_SSO,
    exact: true,
    component: Auth.Sso,
  },
  {
    name: 'welcome',
    path: ROOT,
    exact: true,
    component: Auth.Welcome,
  },
  {
    name: 'not-found',
    path: NOT_FOUND,
    exact: true,
    component: RedirectSurvey,
  },
];
