import {useTranslation} from 'react-i18next';

const NoData = ({imageComponent, title}) => {
  const {t} = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center py-6">
      {imageComponent
        ? (
          <div className="mb-5">
            {imageComponent}
          </div>
        )
        : null}
      <h2 className="text-dawn-gray text-lg font-medium-rubik">
        {t('common.noFound', {name: title})}
      </h2>
      <span className="text-gray-extra-light text-md">
        {t('common.checkSearch')}
      </span>
    </div>
  );
};

export default NoData;
