import {PRINCIPAL_ROUTE_NAME} from '@router/principal';

const en = {
  common: {
    continue: 'Continue',
    next: 'Next',
    saveChanges: 'Save changes',
    save: 'Save',
    upload: 'Upload image',
    loading: 'Loading',
    back: 'Back',
    cancel: 'Cancel',
    delete: 'Delete',
    close: 'Close',
    twentyMinutes: '20 min',
    fiftyMinutes: '50 min',
    resetPasswordError: 'Error occurred while attempting to reset password',
    notFoundEmailError: 'Configured or registered user with this email not found',
    email: 'Email address',
    password: 'Password',
    passwordConfirm: 'Confirm your password',
    corporateEmail: 'Please enter your corporate email address',
    today: 'Today',
    scheduleSession: 'Schedule a session',
    invalidEmail: 'Invalid email address',
    invalidPasswordLength: '8 characters long',
    invalidPasswordMatch: '1 uppercase & 1 lowercase character & 1 number',
    invalidPasswordConfirm: 'Passwords don’t match',
    requiredField: 'This field is required',
    no: 'No',
    checkSearch: 'Add a new one or check if the search is correct',
    noFound: 'No {{name}} found',
    switchAccountError: 'Error occurred while attempting to switch organization',
    dragNDrop: 'Drag and drop some files here, or click to select files',
  },
  modal: {
    sizeLimit: {
      title: 'File size limit has been reached',
      content: 'File is too big for the upload, the limit is {{sizeLimit}}. Please, choose another one.',
    },
    fileType: {
      title: 'Invalid file type',
      content: 'You are only able to use images as a profile picture',
    },
  },
  mobile: {
    notAvailable: 'Allbry web version is not available on mobile',
    availableInLandscape: 'Allbry web version is only available in landscape mode',
    pleaseUseLinks: 'Please, download our mobile app using the link below',
    pleaseUseLinksLandscape: 'You can download our mobile app using the link below',
    appStore: 'App Store',
    googlePlay: 'Google Play',
  },
  introduction: {
    getSupport: 'Get support from Allbry, wherever you are',
    chat: {
      student: 'Jamie',
      counsellor: 'Margo',
      messages: {
        first: 'She said I was lazy!',
        second: 'I have school, dance class, the horses and tutoring!',
        third: 'She doesn’t get me... 😔',
        fourth: 'Oh dear, let’s talk!',
      },
    },
  },
  auth: {
    signUp: {
      startJourney: 'Start your journey with Allbry today',
      withOrganization: 'Sign up with Organization',
      withEmail: 'Sign up with Email',
      signUp: 'Sign up',
      createPass: 'Create your password',
      haveAccount: 'Already have an account?',
      logIn: 'Log in',
    },
    signIn: {
      welcomeBack: 'Welcome back to Allbry',
      withOrganization: 'Log in with Organization',
      withEmail: 'Log in with Email',
      logIn: 'Log in',
      signUp: 'Sign up',
      doNotHaveAnAccount: 'Don’t have an account?',
    },
    forgot: {
      checkStep: 'Check your email',
      weJustSent: 'We’ve just sent',
      resetPassLink: 'reset password link',
      followInstructions: 'to your email. Please check your inbox and follow the instructions.',
      enterEmail: 'Enter your email',
      createNewPass: 'Create new password',
    },
    registrationError: 'User registration failed',
    signInError: 'Wrong email or password',
    invalidPassword: 'Invalid password',
    forgotPassword: 'Forgot password?',
  },
  navbar: {
    switch: 'Set yourself as',
    toOffline: 'Offline',
    toOnline: 'Online',
    appointments: {
      title: 'Appointments',
      text: 'Schedule meetings with counsellors to get more qualified help',
    },
    messages: {
      title: 'Messages',
      text: 'Communicate with counsellors and get help anytime',
    },
    organizations: {
      title: 'Organizations',
    },
    profile: 'Profile',
    settings: {
      title: 'Settings',
    },
    logOut: 'Log out',
    [PRINCIPAL_ROUTE_NAME.ORGANIZATION]: {
      title: 'organization',
    },
    [PRINCIPAL_ROUTE_NAME.STATISTICS]: {
      title: 'statistics',
    },
    [PRINCIPAL_ROUTE_NAME.USERS]: {
      title: 'users',
    },
    counsellors: {
      title: 'Counsellors',
    },
  },
  survey: {
    introStep: 'Hey, let’s start with a quick survey',
    firstStep: {
      question: 'Have you tried Allbry before?',
      letMeIn: 'Yes, let me in!',
      whatIsAllbry: 'What is Allbry?',
    },
    secondStep: {
      question: 'Have you previously visited a counsellor?',
      yes: 'Yes, a couple of times',
      never: 'I’ve never visited',
    },
    outroStep: 'Thank you for your time!',
    helpImprove: 'It’ll take only few minutes, but will help us to improve your experience with Allbry.',
    firstPart: '1/2',
    secondPart: '2/2',
  },
  notFound: {
    code: '404',
    text: 'Not Found',
  },
  settings: {
    title: 'Settings',
    anonymous: {
      title: 'Anonymous mode',
      description: 'Your organization allows you to be anonymous. Your name and profile image will be hidden from other users.',
    },
    dataReset: {
      recurrentReset: {
        title: 'Recurrent data reset',
        disabledResetParagraph: 'Enable recurrent data reset to clear data automatically. Please note that data reset affects chats, appointments, notes.',
        enabledResetParagraph: 'Estimated date for the next reset is {{date}}. Please note that data reset affect chats, appointments, notes.',
        intervalLabel: 'Interval',
        intervals: {
          eachMonth: 'Each month',
          eachThreeMonths: 'Each 3 months',
          eachSixMonths: 'Each 6 months',
        },
        errorGettingResetState: 'Error while getting current recurrent jobs state',
        errorSwitchingResetState: 'Error while changing recurrent jobs state',
        errorIntervalChange: 'Error while changing reset interval',
        successIntervalChange: 'New reset interval has been set',
      },
      manualReset: {
        title: 'Reset data now',
        paragraph: 'Please note that data reset affects chats, appointments, notes.',
        resetButton: 'Clear all data',
        resetSuccess: 'The data has been cleared',
        resetFailure: 'Error while resetting data',
        resetModal: {
          title: 'Clear all data',
          paragraph: 'Are you sure you want to reset all information in chats, appointments, notes?',
          yes: 'Yes',
          no: 'No',
        },
      },
    },
    policy: {
      title: 'Integrity Policy',
      description: {
        firstPart: 'Being fully aware that our work as consultants and engineers is critical to the achievement of a sustainable development of the society and the environment, we have designed and implemented a range of measures aiming at making our ethical conduct one of IRD Engineering main characteristics.',
        secondPart: 'All these, and any future measures, are and will be incorporated in our Integrated Management System, which represents the official Company policy and procedures to ensure that the Company’s services are carried out at a high professional level, consistent with the highest international standards in terms of quality assurance, environmental sustainability and worker’s health and safety.',
      },
      policyCopy: 'A copy of our Code of Ethics and Integrity Policy can be found',
      hereLink: 'here',
    },
    language: {
      title: 'Language',
    },
  },
  profile: {
    personProfile: '{{type}}’s profile',
    overview: 'Profile Overview',
    edit: {
      title: 'Edit profile',
      restriction: 'Only owners and admins can edit.',
      gender: {
        male: 'Male',
        female: 'Female',
        other: 'Other',
      },
      about: 'About',
      changesSaved: 'Changes Saved',
    },
    personalInfo: 'PERSONAL INFORMATION',
    about: 'About me',
    name: 'Name',
    birthday: 'Date of Birth',
    fullBirthday: '{{birthday}} ({{fullYears}} years)',
    appointments: 'APPOINTMENTS',
    noAppointments: 'No appointments found for this category',
    gender: 'Gender',
    age: 'Age',
    lastNote: '{{person}}’s last note will be placed here.',
  },
  appointments: {
    title: 'Appointment',
    session: {
      today: 'Join Now',
      future: 'Cancel or Reschedule',
      completed: 'Book another session',
      cancelled: 'Book another session',
    },
    status: {
      upcoming: 'Upcoming',
      completed: 'Completed',
      cancelled: 'Cancelled',
    },
    booking: {
      title: {
        booking: 'Booking',
        reschedule: 'Reschedule',
      },
      schedule: 'Schedule',
      chooseStudent: 'Choose student',
      selectStudent: 'Select student',
      sessionDuration: 'Choose session duration',
      sessionTime: 'Choose session time',
      success: 'Success',
      successText: 'The session was successfully {{action}}! Now you can check your session in upcoming appointments',
      successBooked: 'booked',
      successRescheduled: 'rescheduled',
      back: 'Back to appointments',
      chooseStudentModal: 'Choose Student',
      done: 'Done',
      chooseCounsellor: 'Choose counsellor',
      chooseCounsellorModal: 'Choose Counsellor',
      selectCounsellor: 'Select counsellor',
      searchStudents: 'Search students',
      noTimeSlots: 'No available time slots',
    },
    detail: {
      scheduleInfoCancelOrReschedule: 'The session is scheduled on {{date}} at {{time}}. You’re able to cancel or reschedule it.',
      scheduleInfoBookAnother: 'The session is scheduled on {{date}} at {{start}} - {{end}}. You are able to book another one.',
      reschedule: 'Reschedule',
      cancel: 'Cancel Appointment',
      areYouSure: 'Are you sure you want to cancel your session?',
      yes: 'Yes, Cancel',
      cancelled: 'The session was cancelled',
      sessionDetails: 'Session details',
    },
    now: 'Now',
    scheduleNew: 'Schedule a new appointment',
    counsellors: 'Counsellors',
    appointments: 'Appointments',
    seeAll: 'See all',
    notExist: 'Appointment does not exist',
    weeksInterval: 'In {{interval}} weeks',
    daysInterval: 'In {{interval}} days',
    dayInterval: 'In {{interval}} day',
    hoursInterval: 'In {{interval}} hours',
    hourInterval: 'In {{interval}} hour',
    minutesInterval: 'In {{interval}} minutes',
    minuteInterval: 'In {{interval}} minute',
    today: 'Today',
    future: 'Future',
    completed: 'Completed',
    cancelled: 'Cancelled',
    noAppointments: 'No appointments here yet...',
    organizationNotWorking: 'Organization is not working on this day',
    scheduleFirst: 'Schedule your first appointment',
    yourFreeTime: 'Your free time',
    freeTime: {
      title: 'Your free time',
      addTime: 'Add time',
      noFreeTimes: 'Please allocate your free time in order students can book the appointments',
      allocateTime: 'Allocate free time',
      editTime: 'Edit free time',
      add: 'Add',
      cancel: 'Cancel',
      date: 'Date',
      ends: 'Ends',
      orgNotWorking: 'Organization is not working',
      repeats: {
        everyday: 'Everyday',
        weeklyOn: 'Weekly on {{day}}',
        weekdays: 'Every weekday (Monday to Friday)',
        none: 'Doesn’t repeat',
      },
      deleteModal: {
        title: 'Delete free time',
        description: 'Are you sure you want to delete your free time?',
        delete: 'Yes, Delete',
      },
      timeAdded: 'Your free time was added',
      timeEdited: 'Your free time was edited',
      timeDeleted: 'Your free time was deleted',
    },
  },
  notes: {
    title: 'Notes',
    note: 'Note',
    add: 'Add note',
    personNotes: '{{person}}’s notes',
    personNote: '{{person}}’s note',
    create: 'Create a note',
    saveText: 'Take notes about the student.',
    autoSaveText: 'Take notes about student. All notes will be saved automatically',
    autoSaveTextDuringVideo: 'Take notes during the session. All notes will be saved automatically',
    metaAppointment: '{{person}} finished appointment with {{student}}, {{date}}',
    metaChat: '{{person}} finished chat with {{student}}, {{date}}',
    createdBy: '{{date}} by {{person}}',
    delete: 'Delete note',
    deleteQuestion: 'Are you sure you want to delete this note?',
    yes: 'Yes, delete',
    saved: 'The note has been saved',
    deleted: 'The note has been deleted',
  },
  videoCall: {
    leaveCallModal: {
      title: 'Finishing the appointment',
      paragraph: 'Already done with the appointment? If so, click End the call option and fill the report. If not, click Leave the call and you’ll be able to rejoin it.',
      leaveCall: 'Leave the call',
      endCall: 'End the call',
    },
    videoAccessModal: {
      title: 'Camera or microphone is blocked',
      description_1: 'Allbry requires access to your camera and microphone. Click the camera blocked icon',
      description_2: 'in your browser’s adress bar.',
      leaveCall: 'Leave the call',
    },
  },
  chat: {
    end: 'End chat',
    endInfoForCounsellor: 'This chat was ended on {{time}} by you. To restart the conversation you just need to write something.',
    noMessages: 'No messages here yet',
    sendFirstMessage: 'Send your first message to {{person}}',
    writeMessage: 'Write a message...',
    endChatQuestion: 'Are you sure you want to end the chat? The chat will not be deleted, you can restart it by sending the message.',
    attachmentInfo: '{{fileSize}}, {{date}} at {{time}}',
    sharedFiles: 'SHARED FILES',
    noFiles: 'No shared files here yet..',
    general: 'General',
    messages: 'Messages',
    chatEnded: '(Chat ended)',
    attachment: '(Attachment)',
    startTitle: 'Who you want to talk with?',
    noStudentsFound: 'No students found',
    noStudentsAvailable: 'No students are available',
    noStudentsAvailableToChat: 'There are no available students to start a chat with',
    noCounsellorsAvailable: 'No counsellors are available',
    noCounsellorsAvailableToChat: 'There are no available counsellors to start a chat with',
    start: 'Start chat',
    more: 'View more',
    selectChat: 'Select a chat to start messaging',
    archive: 'Archive',
    delete: 'Delete chat',
    organizationDoesntWork: 'Organization doesn’t work now. It’ll take longer time to receive the answer. We recommend to send messages at {{start}} - {{end}}',
    organizationDoesntWorkToday: 'Organization is not working today. It’ll take longer time to receive the answer',
    endInfoForStudent: 'This chat was ended on {{time}} by {{person}}. To restart the conversation you just need to write something.',
    sendAfterWork: 'Send message outside working hours',
    workingHours: 'Working hours of organization: {{start}} - {{end}}. If you want to send message outside working hours, please note that you receive answer in working hours.',
    workingHoursToday: 'Organization is not working today. If you want to send message outside working hours, please note that you receive answer in working hours.',
    ok: 'Ok, got it',
    deleteChatQuestion: 'Are you sure you want to delete the chat?',
    archiveChat: 'Archive chat',
    unarchiveChat: 'Unarchive chat',
    deleted: 'Chat deleted',
    archived: 'Chat archived',
    unarchived: 'Chat unarchived',
  },
  reports: {
    title: 'Reporting',
    selectTopics: '1. Select the topic in the conversation',
    concernScale: '2. How worried are you about the student?',
    participationThanks: {
      title: 'Thank you!',
      details: 'Thank you for providing this information. It’s really helpful for us.',
      close: 'Close',
    },
    topics: {
      startOfSession: 'Start of the session',
      checkIn: 'Check-in',
      basicCare: 'Basic care',
      identity: 'Identity',
      anxiety: 'Anxiety',
      stress: 'Stress',
      physicalHealth: 'Physical health',
      training: 'Training',
      crime: 'Crime',
      sorrow: 'Sorrow',
      socialRelationship: 'Social relationship',
      workLife: 'Work life',
      shame: 'Shame',
      honor: 'Honor',
      careFailure: 'Child neglect',
      economy: 'Economy',
      security: 'Security',
      stimulationAndGuidance: 'Stimulation and guidance',
      socialOrientation: 'Social orientation',
      emotionalAvailability: 'Emotional availability',
      love: 'Love',
      mentalHealth: 'Mental health',
      mentalAbuse: 'Mental abuse',
      physicalAbuse: 'Physical abuse',
      loveRelations: 'Love relations',
      familyRelations: 'Family relations',
      sexualAbuse: 'Sexual abuse',
      economicalAbuse: 'Financial abuse',
      drugUse: 'Drug use',
      performanceAnxiety: 'Performance anxiety',
      loneliness: 'Loneliness',
      grooming: 'Grooming',
    },
  },
  principal: {
    validation: {
      notEmpty: 'Value can’t be empty',
      noSpaces: 'Value can’t have spaces',
      lessThan30Symbols: '{{value}} should be less than 30 symbols',
      exists: 'Attribute already exists',
      ageLessThan8: 'Your age cannot be less than 8',
      ageLessThan18: 'Your age cannot be less than 18',
      required: '{{value}} is required',
      requiredName: 'Name is required',
      requiredEmail: 'Email is required',
      notValidEmail: 'Not valid email',
    },
    addCustomField: 'Add custom field',
    overview: '{{name}} Overview',
    schedule: 'Organization schedule',
    addCustomAttributes: 'Add custom attributes',
    customFieldsDescription: 'While creating the user you’ll see added custom fields.',
    anonymous: 'Students in your organization are allowed to be anonymous. Their names and profile images will be hidden from other users.',
    removeChatTitle: 'Remove chats',
    removeChatDescription: 'Students in your organization are allowed to remove chats with counsellors.',
    archiveChatTitle: 'Archive chats',
    archiveChatDescription: 'Students in your organization are allowed to archive chats with counsellors.',
    to: 'To',
    from: 'From',
    workingHours: 'Working hours',
    custom: 'Custom',
    weekDays: 'Weekdays',
    allDays: 'All days',
    days: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    startLaterThanEnd: 'Start time is later than end time',
    endBeforeStart: 'The end time is before the start time',
    edit: 'Edit User',
    add: 'Add User',
    student: 'Student',
    counsellor: 'Counsellor',
    allbryCounsellor: 'Allbry Counsellor',
    userCreated: 'User created',
    errorCreatingUser: 'Error creating user',
    userUpdated: 'User updated',
    errorUpdatingUser: 'Error updating user',
    userTable: {
      viewProfile: 'View profile',
      editProfile: 'Edit profile',
      users: 'users',
      active: 'Active',
      inactive: 'Inactive',
      deactivated: 'Deactivated',
      nameSurname: 'Name Surname',
      birthday: 'Date of birth',
      activate: 'Activate',
      deactivate: 'Deactivate',
      profileActivated: 'Profile activated',
      profileDeactivated: 'Profile deactivated',
      activatingError: 'Error activating profile',
      deactivatingError: 'Error deactivating profile',
      generateCsvTemplate: 'Generate csv template',
      generateXlsxTemplate: 'Generate xlsx template',
      importUsers: 'Import users from xls or xlsx',
      csvImportTitle: 'Import is in progress',
      csvImportBody: 'Importing users may take some time. Please, don’t close the window',
      importButton: 'Import',
      selectFiles: 'Select file to import',
      importTitle: 'Import users',
      usersAddSuccess: 'Users successfully added',
      usersAddError: 'Error in importing users',
      wrongFormat: 'File format should be xls or xlsx',
      wrongImportData: 'Error in line {{row}}! Check imported file',
    },
    csv: {
      custom: 'Fill custom attribute',
      email: 'Fill person’s email',
      role: 'Fill number: 1 - student, 2 - counsellor',
      gender: 'Fill number: 1 - male, 2 - female, 3 - other',
      year: 'Year of birth',
      month: 'Month of birth (number)',
      day: 'Day of birth',
      nameSurname: 'Fill person’s name and surname',
    },
    usersOrganization: '{{name}} Users',
    addUserButton: 'Add user',
    about: 'About me',
    user: {
      role: 'Role',
      email: 'Email',
      status: 'Status',
    },
    enterFieldName: 'Enter Field name (E.x. Class)',
    result: '{{value}} result',
    results: '{{value}} results',
    noResults: 'No results',
    day: 'Day',
    month: 'Month',
    year: 'Year',
    statistic: {
      title: 'Statistics',
      calendar: 'Calendar',
      today: 'Today',
      thisWeek: 'This Week',
      thisMonth: 'This month',
      thisQuarter: 'This quarter',
      thisYear: 'This year',
      userTypes: 'User types',
      notFound: 'No statistics found',
      topics: 'Topics',
      experience: 'Experience',
      counselling: 'Counselling',
      usersAmount: '{{amount}} users',
      levelOfWorry: 'Level of worry',
      chats: 'Chats',
      appointments: 'Appointments',
      all: 'All',
      allTitle: {
        users: '{{value}} users',
        user: '{{value}} users',
        times: '{{value}} times',
        time: '{{value}} times',
        zero: '0',
      },
      registered: 'Registered',
      inactive: 'Inactive',
      todayInterval: 'Today {{start}} - {{end}}',
      pdfExport: 'Export as PDF',
    },
  },
  admin: {
    title: 'Allbry Admin',
    modals: {
      addCounsellors: {
        choose: 'Choose Counsellor',
        search: 'Search counsellors',
      },
    },
    organization: {
      organizations: 'organizations',
      edit: 'Edit organization',
      details: 'Organization details',
      closed: 'Closed',
      start: 'Start Date',
      end: 'End Date',
      principal: 'Principal',
      principals: 'Principals',
      create: 'Create organization',
      view: 'View',
      editPopup: 'Edit',
      deactivated: 'Organization deactivated',
      deactivatedError: 'Error deactivating organization',
      name: 'Organization Name',
      startEnd: 'Start date/End date',
      addPrincipal: '+ Add Principal',
      addCounsellor: '+ Add Counsellor',
      searchOrganizations: 'Search organizations by name',
      edited: 'Organization edited',
      created: 'Organization created',
      createdError: 'Error creating organization',
      editError: 'Error editing organization',
      longName: 'Too long name',
      startDateRequired: 'Start date is required',
      endBeforeStart: 'End date cannot be before start date',
      longEmail: 'Too long email',
      emailFormat: 'Wrong email format',
      duplicateEmails: 'Duplicate emails',
      userExists: 'User already exists',
      atLeastOne: 'At least one must be active',
    },
    counsellors: {
      title: 'Allbry Counsellors',
      add: 'Add counsellor',
      edit: 'Edit counsellor',
      profile: 'Counsellor\'s profile',
      searchCounsellors: 'Search counsellors by name',
    },
  },
  allbryCounsellor: {
    choose: 'Choose organization',
    notFound: 'No organization found...',
    text: 'You haven’t been added to any organization yet.',
    error: 'Error while attempting to login in organization',
  },
  filterModal: {
    filter: 'Filter',
    addFilter: 'Add filter',
    description: 'Show users that match all conditions:',
    addCondition: '+ Add condition',
    is: 'is',
    isNot: 'is not',
    chooseFilter: 'Choose filter',
    conditionValue: 'Enter condition value',
  },
};

export default en;
