function Male() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_4693_22172" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
        <circle r="59.5" transform="matrix(-1 0 0 1 60 60)" fill="white" stroke="black" />
      </mask>
      <g mask="url(#mask0_4693_22172)">
        <path d="M185.416 136.936C185.405 136.891 185.393 136.847 185.382 136.788C171.918 138.416 158.44 140.044 144.976 141.672C138.957 142.395 131.735 144.992 125.794 142.119C119.556 139.109 115.271 133.239 111.263 127.797C106.386 121.188 95.3881 101.691 89.897 94.8956C86.1367 101.295 85.3114 119.861 78.6852 122.921C82.6059 127.264 86.5557 131.609 90.8485 135.613C97.311 142.035 103.274 148.964 109.784 155.331C113.476 158.945 119.474 161.618 124.631 161.814C127.087 161.905 129.528 161.338 131.925 160.768C150.675 156.297 169.455 151.828 188.22 147.344C187.286 143.87 186.351 140.41 185.416 136.936Z" fill="#FD7064" />
        <path d="M108.398 111.619C108.974 106.466 108.785 104.164 105.652 99.3922C98.3749 88.2788 81.8706 87.0368 69.0986 86.9614C45.6387 86.8177 18.9052 84.284 6.47466 103.96C3.74443 108.27 1.74926 112.877 -0.102779 117.493C-2.68655 123.927 -5.27128 130.375 -7.67903 136.865C-12.0301 148.533 -17.2544 160.362 -20.2423 172.339C-23.2804 184.532 -19.3781 196.938 -18.4864 209.216C-18.372 210.741 -15.7411 227.407 -17.1433 227.956C-17.0698 227.931 -17.0132 227.891 -16.9218 227.868C-12.2288 226.474 -7.24303 225.814 -2.22718 225.782L1.63168 175.527C1.65964 175.106 1.65183 174.683 1.62611 174.258C2.44945 173.496 3.1185 172.622 3.59847 171.619L19.2855 138.626L13.1499 202.598L58.2727 207.213L91.7372 209.596C91.7372 209.596 103.724 148.711 104.694 141.951C105.888 133.719 106.581 125.439 107.669 117.185C107.915 115.364 108.202 113.487 108.398 111.619Z" fill="#7D7D7D" />
        <path d="M62.2206 99.2828C56.4746 99.6157 51.5611 95.5272 51.2489 90.1404L50.0496 69.3837L70.8556 68.17L72.055 88.9267C72.3672 94.3135 67.9667 98.9499 62.2206 99.2828Z" fill="#C6C6C6" />
        <path d="M49.7058 70.8824C49.7058 70.8824 54.2163 85.4376 61.3298 85.5624C72.3065 85.745 71.2709 73.8347 71.2709 73.8347C71.2709 73.8347 66.7003 78.5165 59.9999 77.9413C52.5227 77.312 49.7058 70.8824 49.7058 70.8824Z" fill="#8F8989" />
        <path d="M41.6333 59.2454L38.1356 42.422C36.0884 33.7305 44.9744 28.4752 50.4831 28.1163L69.5582 26.8464C75.4077 26.4629 82.5928 30.6112 80.1226 40.7201L79.1633 55.8295L41.6333 59.2454Z" fill="#6A6A6A" />
        <path d="M75.0976 60.9286C78.7726 63.9287 85.9685 51.4142 82.395 48.2022C78.8215 44.9901 76.1002 50.438 76.1002 50.438L75.7878 55.1417L75.0307 59.5243L74.7516 60.8765L75.0976 60.9286Z" fill="#C6C6C6" />
        <path d="M45.6083 63.0676C41.5158 66.135 33.4243 53.7572 37.3853 50.4769C41.3463 47.1966 44.2744 52.6697 44.2744 52.6697L45.7822 56.9402L45.6564 61.6855L45.9746 63.0336L45.6083 63.0676Z" fill="#C6C6C6" />
        <path d="M79.8604 44.0132C80.3461 51.9488 79.6795 58.8455 78.9059 63.7665C78.139 68.5859 75.4252 72.8968 71.4043 75.8086L70.6688 76.343C66.4139 79.4143 60.6987 79.8075 56.0382 77.3253C51.3342 74.8256 47.8954 70.5435 46.6079 65.4856C44.2689 56.3041 43.4472 46.0277 43.4472 46.0277C43.4472 46.0277 40.5417 26.0909 60.2028 25.3844C79.8628 24.6924 79.8604 44.0132 79.8604 44.0132Z" fill="#C6C6C6" />
        <path d="M76.5845 13.8859C68.8922 10.5708 56.4326 16.8176 49.99 21.7268C49.6767 19.5263 48.4057 20.0542 48.4057 20.0542C36.5289 26.2647 42.0186 35.6403 42.0186 35.6403C42.0186 35.6403 42.0685 35.6523 42.1522 35.6777C41.4651 38.8769 42.1331 47.3914 46.1764 58.2353C46.1764 58.2353 49.8763 45.188 48.4057 37.9412C54.5457 39.2176 67.6832 42.495 76.9441 36.8847C79.2739 42.8319 80.7226 50.7788 80.7226 50.7788C80.2076 43.2969 81.2197 38.1339 79.7616 34.7989C80.2793 34.2237 82.3004 33.3678 83.8967 30.8452C85.4929 28.3225 87.4965 18.5934 76.5845 13.8859Z" fill="#6A6A6A" />
        <path d="M78.8062 88.3277L69.3291 85.0217L60.6062 85.7932L50.5296 85.9354L43.1046 90.1291C43.1046 90.1291 47.8694 100.387 64.9461 99.6123C64.9882 99.6102 65.1705 99.601 65.2266 99.5981C82.3085 98.649 78.8062 88.3277 78.8062 88.3277Z" fill="#C6C6C6" />
        <path d="M85.8089 25.1476C84.4999 30.6017 80.5605 35.3125 75.4161 37.5518C74.5606 37.9324 73.905 36.6203 74.774 36.2551C79.6042 34.1407 83.2131 29.8017 84.4429 24.6631C84.6486 23.7581 86.0291 24.2435 85.8089 25.1476Z" fill="#585858" />
        <path d="M74.358 22.6569C74.2178 29.0734 69.195 34.9006 62.8994 36.0248C61.9837 36.1886 61.7052 34.7605 62.6208 34.5967C68.3173 33.5894 72.7847 28.3301 72.9163 22.5527C72.9405 21.6141 74.3822 21.7183 74.358 22.6569Z" fill="#585858" />
        <path d="M60.5048 28.5903C59.1706 32.8908 55.6669 36.3036 51.3865 37.5941C51.0153 37.7007 50.5996 37.3814 50.5212 37.0263C50.4322 36.6121 50.7164 36.281 51.0886 36.1599C54.8933 35.0127 57.9697 31.8924 59.1388 28.1059C59.4161 27.2202 60.7831 27.6901 60.5048 28.5903Z" fill="#585858" />
        <path d="M79.4119 91.2061C79.3488 93.3536 78.4665 95.4496 76.8909 96.8899C75.2775 98.3815 73.1473 99.0116 71.055 99.3974C66.0635 100.298 60.7695 100.876 55.7907 99.6031C55.0092 99.3974 55.3369 98.1629 56.131 98.3687C60.6687 99.5388 65.3955 99.0373 69.9584 98.2915C71.8995 97.9829 73.9414 97.5714 75.5548 96.3626C77.1556 95.1667 78.0884 93.225 78.1514 91.2061C78.1766 90.3831 79.4371 90.3831 79.4119 91.2061Z" fill="white" />
      </g>
    </svg>
  );
}

export default Male;
