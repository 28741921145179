import React from 'react';
import {Arrow} from '@assets/icons';
import {useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const Back = ({link, className}) => {
  const {goBack, push} = useHistory();
  const {t} = useTranslation();

  const backHandler = async () => {
    if (link) {
      return push(link);
    }

    return goBack();
  };

  return (
    <div className={clsx('flex flex-row items-center cursor-pointer hover:opacity-70 transition-opacity duration-30', className)} onClick={() => backHandler()}>
      <div className="self-stretch">
        <Arrow />
      </div>
      <span className="ml-6 self-center text-dawn-gray font-extrabold">{t('common.back')}</span>
    </div>
  );
};
export default Back;
