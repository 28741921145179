import {
  EDIT_PROFILE, PROFILE, SETTINGS, VIDEO_ROOM,
} from '@router/consts';
import Root from '@pages/Root';

export const ROOT_ROUTES = [
  {
    name: 'profile',
    path: `${PROFILE}/:id`,
    exact: true,
    component: Root.Profile,
  },
  {
    name: 'editProfile',
    path: EDIT_PROFILE,
    component: Root.EditProfile,
  },
  {
    name: 'settings',
    path: SETTINGS,
    exact: true,
    Icon: null,
    component: Root.Settings,
  },
  {
    name: 'videoRoom',
    path: VIDEO_ROOM,
    component: Root.VideoRoom,
  },
];
