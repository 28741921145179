import {
  APPOINTMENT_DETAIL, APPOINTMENTS, COUNSELLORS, MESSAGES, APPOINTMENTS_BOOKING,
} from '@router/consts';
import Student from '@pages/Student';
import {Calendar} from '@assets/icons';
import Chat from '@components/Navbar/ChatIcon';

export const STUDENT_MENU_ROUTES = [
  {
    name: 'appointments',
    path: APPOINTMENTS,
    icon: Calendar,
    exact: true,
    isMenu: true,
    component: Student.Appointments,
  },
  {
    name: 'messages',
    exact: true,
    isMenu: true,
    icon: Chat,
    path: MESSAGES,
    component: Student.Messages,
  },
];

export const STUDENT_ROUTES = [
  ...STUDENT_MENU_ROUTES,
  {
    name: 'appointmentBooking',
    exact: true,
    path: APPOINTMENTS_BOOKING,
    component: Student.Booking,
  },
  {
    name: 'appointmentDetail',
    path: APPOINTMENT_DETAIL,
    component: Student.AppointmentDetail,
  },
  {
    name: 'messages',
    path: MESSAGES,
    exact: true,
    component: Student.Messages,
  },
  {
    name: 'counsellors',
    path: COUNSELLORS,
    exact: true,
    component: Student.CounsellorsList,
  },
];
