function СolorMale() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="60" cy="60" r="60" fill="#F5F3F0" />
      <mask id="mask0_5105_24122" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
        <circle r="59.7059" transform="matrix(-1 0 0 1 60 60)" fill="white" stroke="black" strokeWidth="0.588235" />
      </mask>
      <g mask="url(#mask0_5105_24122)">
        <path d="M185.417 136.935C185.405 136.891 185.393 136.846 185.383 136.787C171.919 138.415 158.44 140.043 144.976 141.671C138.957 142.394 131.735 144.992 125.794 142.118C119.557 139.108 115.271 133.239 111.263 127.796C106.386 121.187 95.3885 101.69 89.8974 94.8949C86.1371 101.294 85.3118 119.861 78.6856 122.92C82.6063 127.263 86.556 131.609 90.8489 135.612C97.3114 142.034 103.274 148.963 109.784 155.33C113.477 158.944 119.474 161.617 124.632 161.814C127.087 161.904 129.528 161.337 131.925 160.767C150.676 156.296 169.455 151.827 188.221 147.343C187.286 143.869 186.351 140.409 185.417 136.935Z" fill="#FD7064" />
        <path d="M108.398 111.618C108.974 106.466 108.785 104.164 105.652 99.3915C98.3753 88.2781 81.8711 87.0361 69.0991 86.9607C45.6392 86.8171 18.9057 84.2833 6.47515 103.96C3.74492 108.27 1.74975 112.876 -0.102291 117.492C-2.68606 123.926 -5.27079 130.375 -7.67855 136.864C-12.0296 148.532 -17.2539 160.361 -20.2418 172.338C-23.2799 184.531 -19.3776 196.937 -18.4859 209.216C-18.3715 210.74 -15.7406 227.407 -17.1428 227.955C-17.0693 227.931 -17.0127 227.891 -16.9213 227.868C-12.2283 226.473 -7.24254 225.813 -2.22669 225.781L1.63217 175.526C1.66013 175.105 1.65232 174.682 1.6266 174.257C2.44994 173.496 3.11899 172.621 3.59896 171.618L19.2859 138.626L13.1504 202.597L58.2732 207.212L91.7377 209.595C91.7377 209.595 103.724 148.71 104.694 141.951C105.888 133.718 106.582 125.438 107.669 117.184C107.916 115.363 108.202 113.487 108.398 111.618Z" fill="#324257" />
        <path d="M62.221 99.2819C56.475 99.6147 51.5615 95.5263 51.2493 90.1395L50.0499 69.3828L70.856 68.1691L72.0554 88.9258C72.3676 94.3126 67.967 98.949 62.221 99.2819Z" fill="#F9D4C7" />
        <path d="M49.7063 70.8823C49.7063 70.8823 54.2168 85.4374 61.3303 85.5622C72.307 85.7448 71.2714 73.8345 71.2714 73.8345C71.2714 73.8345 66.7008 78.5163 60.0004 77.9411C52.5232 77.3118 49.7063 70.8823 49.7063 70.8823Z" fill="#472E2A" fillOpacity="0.24" />
        <path d="M41.634 59.2449L38.1364 42.4215C36.0891 33.73 44.9751 28.4746 50.4839 28.1157L69.5589 26.8459C75.4084 26.4623 82.5935 30.6107 80.1233 40.7196L79.164 55.8289L41.634 59.2449Z" fill="#685353" />
        <path d="M75.0972 60.9284C78.7721 63.9284 85.968 51.4139 82.3945 48.2019C78.821 44.9899 76.0997 50.4377 76.0997 50.4377L75.7873 55.1415L75.0302 59.5241L74.7511 60.8762L75.0972 60.9284Z" fill="#F9D4C7" />
        <path d="M45.608 63.0672C41.5156 66.1346 33.4241 53.7568 37.3851 50.4764C41.346 47.1961 44.2741 52.6692 44.2741 52.6692L45.782 56.9398L45.6561 61.6851L45.9744 63.0332L45.608 63.0672Z" fill="#F9D4C7" />
        <path d="M79.8609 44.0131C80.3466 51.9487 79.68 58.8453 78.9064 63.7664C78.1395 68.5857 75.4257 72.8967 71.4048 75.8085L70.6693 76.3429C66.4144 79.4141 60.6992 79.8074 56.0387 77.3252C51.3347 74.8255 47.8959 70.5434 46.6084 65.4854C44.2694 56.304 43.4477 46.0275 43.4477 46.0275C43.4477 46.0275 40.5422 26.0908 60.2032 25.3843C79.8633 24.6923 79.8609 44.0131 79.8609 44.0131Z" fill="#F9D4C7" />
        <path d="M76.585 13.8856C68.8927 10.5705 56.4331 16.8173 49.9905 21.7265C49.6772 19.526 48.4062 20.0539 48.4062 20.0539C36.5294 26.2644 42.0191 35.64 42.0191 35.64C42.0191 35.64 42.069 35.652 42.1527 35.6774C41.4656 38.8766 42.1336 47.391 46.1769 58.235C46.1769 58.235 49.8768 45.1876 48.4062 37.9409C54.5461 39.2173 67.6837 42.4947 76.9446 36.8844C79.2744 42.8316 80.7231 50.7785 80.7231 50.7785C80.2081 43.2966 81.2202 38.1336 79.762 34.7986C80.2798 34.2234 82.3009 33.3675 83.8972 30.8449C85.4934 28.3222 87.497 18.5931 76.585 13.8856Z" fill="#685353" />
        <path d="M59.8006 54.0053C59.8161 55.3839 59.0706 56.5132 58.1495 56.5088C57.2266 56.5189 56.4634 55.405 56.4622 54.028C56.4466 52.6494 57.1921 51.5201 58.1133 51.5245C59.0218 51.5127 59.785 52.6266 59.8006 54.0053Z" fill="#1A1A1A" />
        <path d="M73.4907 53.4515C73.5063 54.8302 72.7608 55.9594 71.8396 55.955C70.9168 55.9651 70.1536 54.8512 70.1524 53.4743C70.1368 52.0956 70.8823 50.9664 71.8035 50.9707C72.7264 50.9606 73.4751 52.0728 73.4907 53.4515Z" fill="#1A1A1A" />
        <path d="M65.8545 57.2299L67.3186 61.8426C67.3084 62.0625 67.2579 62.2604 67.1645 62.4505C66.8245 63.1875 65.9408 63.5009 65.175 63.2442L63.5289 62.7101M59.7404 68.9598C59.7404 68.9598 60.6651 69.4818 63.1792 69.6257C65.4619 69.7426 66.92 69.3556 66.92 69.3556" stroke="black" strokeWidth="1.17647" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M59.8701 49.6201L59.8351 47.6492L54.5478 48.1146C53.8805 48.1724 53.2408 48.3924 52.7467 48.7241L50.7122 50.5429L54.1986 49.9141L59.8701 49.6201Z" fill="#1A1A1A" />
        <path d="M69.4474 49.0805L69.456 47.1188L73.7405 46.9853C74.2825 46.9748 74.7964 47.1076 75.2125 47.3901L76.8701 48.9736L74.0312 48.7473L69.4474 49.0805Z" fill="#1A1A1A" />
        <path d="M78.8069 88.3269L69.3298 85.021L60.607 85.7924L50.5304 85.9347L43.1053 90.1284C43.1053 90.1284 47.8702 100.386 64.9468 99.6116C64.9889 99.6094 65.1713 99.6002 65.2274 99.5974C82.3092 98.6483 78.8069 88.3269 78.8069 88.3269Z" fill="#F9D4C7" />
        <path d="M85.8094 25.147C84.5004 30.6012 80.561 35.3119 75.4166 37.5512C74.5611 37.9319 73.9055 36.6197 74.7745 36.2545C79.6047 34.1402 83.2136 29.8012 84.4433 24.6626C84.6491 23.7576 86.0296 24.243 85.8094 25.147Z" fill="#100E0E" />
        <path d="M74.3576 22.6567C74.2173 29.0732 69.1945 34.9004 62.8989 36.0246C61.9832 36.1884 61.7047 34.7603 62.6203 34.5966C68.3168 33.5892 72.7842 28.3299 72.9158 22.5525C72.94 21.6139 74.3818 21.7181 74.3576 22.6567Z" fill="#100E0E" />
        <path d="M60.5054 28.5892C59.1712 32.8897 55.6675 36.3025 51.3871 37.593C51.016 37.6996 50.6002 37.3803 50.5218 37.0252C50.4328 36.611 50.717 36.2799 51.0892 36.1588C54.8939 35.0116 57.9704 31.8913 59.1394 28.1048C59.4167 27.2191 60.7837 27.689 60.5054 28.5892Z" fill="#100E0E" />
        <path d="M79.4125 91.2044C79.3494 93.3519 78.4671 95.4479 76.8915 96.8881C75.2781 98.3798 73.1479 99.0099 71.0556 99.3957C66.0641 100.296 60.7702 100.874 55.7913 99.6014C55.0098 99.3957 55.3375 98.1612 56.1316 98.367C60.6693 99.5371 65.3961 99.0356 69.959 98.2898C71.9001 97.9812 73.942 97.5697 75.5554 96.3609C77.1562 95.165 78.089 93.2233 78.152 91.2044C78.1772 90.3814 79.4377 90.3814 79.4125 91.2044Z" fill="white" />
      </g>
    </svg>
  );
}

export default СolorMale;
