import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './i18n';
import UserProvider from './contexts/User';
import ChatProvider from './contexts/Messages';
import AppointmentReportProvider from './contexts/AppointmentReport';
import AdminApp from './_admin';

if (process.env.REACT_APP_TYPE === 'admin') {
  ReactDOM.render(
    <React.StrictMode>
      <AdminApp />
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <UserProvider>
          <AppointmentReportProvider>
            <ChatProvider>
              <App />
            </ChatProvider>
          </AppointmentReportProvider>
        </UserProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
