import config from '@config/config';
import ApiService from './api';

class ProfileApi extends ApiService {
  constructor() {
    if (ProfileApi.instance) {
      return ProfileApi.instance;
    }

    super(config.accountApiUrl);
    ProfileApi.instance = this;
  }

  async getProfileByUserId(accountId, userId) {
    const response = await this.http.get(`/accounts/${accountId}/persons?userId=${userId}`);
    return response.data;
  }

  async getProfileById(accountId, id, withAttr = false) {
    const response = await this.http.get(`/accounts/${accountId}/persons/${id}${withAttr ? '?populateAttributes=true' : ''}`);
    return response.data;
  }

  async updateProfileById(accountId, id, data) {
    const response = await this.http.put(`/accounts/${accountId}/persons/${id}`, data);
    return response.data;
  }

  async getProfilesByType(accountId, type) {
    const response = await this.http.get(`/accounts/${accountId}/persons?type=${type}`);
    return response.data;
  }

  async getAllPersons(accountId, withAttr = false) {
    const response = await this.http.get(`/accounts/${accountId}/persons${withAttr ? '?populateAttributes=true' : ''}`);
    return response.data;
  }

  async createProfile(accountId, data) {
    const response = await this.http.post(`/accounts/${accountId}/persons`, data);
    return response.data;
  }

  async getProfileAttributesByPersonId(accountId, personId) {
    const response = await this.http.get(`/accounts/${accountId}/persons/${personId}/attributes`);
    return response.data;
  }

  async createProfileAttribute(accountId, personId, data) {
    const response = await this.http.post(`/accounts/${accountId}/persons/${personId}/attributes`, data);
    return response.data;
  }

  async updateProfileAttribute(accountId, personId, attributeId, data) {
    const response = await this.http.put(`/accounts/${accountId}/persons/${personId}/attributes/${attributeId}`, data);
    return response.data;
  }
}

const profileApiInstance = new ProfileApi();
export default profileApiInstance;
