/* eslint-disable no-case-declarations */
import {
  createContext, useEffect, useState, useCallback, useContext,
} from 'react';

import LocalStorageService from '@services/localStorage';
import ReportService from '@services/api/report';
import VideoService from '@services/api/video';
import ChatRoomService from '@services/api/chat';
import {USER_ROLES} from '@utils/consts';
import {UserContext} from './User';

export const AppointmentReportContext = createContext({
  appointmentReportItem: null,
  createReport: async () => {},
  createAppointmentReportItem: () => {},
  createAppointmentReportCounsellorId: () => {},
});

const AppointmentReportProvider = ({children}) => {
  const {profile: currentProfile} = useContext(UserContext);
  const [appointmentReportItem, setAppointmentReportItem] = useState();

  useEffect(async () => {
    const reportItem = LocalStorageService.getAppointmentReportItem();
    const counsellorId = LocalStorageService.getAppointmentReportCounsellorId();

    if (!reportItem
        || !currentProfile
        || currentProfile.type !== USER_ROLES.COUNSELLOR_ROLE
        || currentProfile.id !== counsellorId) {
      return;
    }

    setAppointmentReportItem(reportItem);
  }, [currentProfile]);

  const createReport = useCallback(async (data) => {
    if (!appointmentReportItem || !currentProfile) {
      return;
    }

    let entityId;
    let anotherParticipant;
    switch (appointmentReportItem.type) {
      case 'appointment':
        const room = await VideoService.getRoomById(currentProfile.accountId, appointmentReportItem.reportItemId, {
          includeParticipants: true,
        });

        entityId = room.eventId;
        anotherParticipant = room.participants.find((participant) => participant.userId !== currentProfile.userId);
        break;
      case 'chat':
        const chatRoom = await ChatRoomService.getChatRoomById(currentProfile.accountId, appointmentReportItem.reportItemId, true);
        entityId = chatRoom.id;
        anotherParticipant = chatRoom.participants.find((participant) => participant.userId !== currentProfile.userId);
        break;
      default:
        LocalStorageService.removeAppointmentReportItem();
        LocalStorageService.removeAppointmentReportCounsellorId();
        setAppointmentReportItem(null);
        return;
    }

    const report = {
      accountId: currentProfile.accountId,
      reporterId: currentProfile.userId,
      entityId,
      type: appointmentReportItem.type,
      userId: anotherParticipant.userId,
      ...data,
    };

    await ReportService.createReport(currentProfile.accountId, report);
    LocalStorageService.removeAppointmentReportItem();
    LocalStorageService.removeAppointmentReportCounsellorId();
    setAppointmentReportItem(null);
  }, [appointmentReportItem, currentProfile]);

  const createAppointmentReportItem = useCallback((reportItem) => {
    LocalStorageService.setAppointmentReportItem(reportItem);
    setAppointmentReportItem(reportItem);
  }, []);

  const createAppointmentReportCounsellorId = useCallback((id) => {
    LocalStorageService.setAppointmentReportCounsellorId(id);
  }, []);

  const value = {
    appointmentReportItem,
    createAppointmentReportItem,
    createAppointmentReportCounsellorId,
    createReport,
  };

  return (
    <AppointmentReportContext.Provider value={{...value}}>{children}</AppointmentReportContext.Provider>
  );
};

export default AppointmentReportProvider;
