import clsx from 'clsx';
import fileSize from 'filesize';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';

import Loader from '@components/Loader';

const Attachment = ({
  fileName, fileType, size, isLoading, sentAt, onClick, className, maxFileNameLength = 100,
}) => {
  const {t} = useTranslation();

  return (
    <div onClick={onClick} className={clsx('flex cursor-pointer rounded-lg flex-row items-center', className)}>
      <div className={clsx(
        'flex items-center justify-center min-w-14 w-14 min-h-14 h-14 rounded-xl bg-primary',
        isLoading
          ? 'bg-opacity-60'
          : 'bg-opacity-70',
      )}
      >
        {isLoading
          ? <Loader height={40} width={40} />
          : (
            <p className="font-medium-rubik text-base text-white">
              {fileType}
            </p>
          )}
      </div>
      <div className="flex flex-col ml-3 min-w-0">
        <p className="font-bold text-dawn-gray mb-0.5 text-lg truncate">
          {`${fileName}.${fileType}`.length > maxFileNameLength
            ? `${`${fileName}.${fileType}`.slice(0, maxFileNameLength)}...`
            : `${fileName}.${fileType}`}
        </p>
        <p className="font-light-rubik font-black text-sm text-gray-extra-light">
          {sentAt ? t('chat.attachmentInfo', {fileSize: fileSize(size), date: format(sentAt, 'dd.MM.yyyy'), time: format(sentAt, 'HH:mm')}) : fileSize(size)}
        </p>
      </div>
    </div>
  );
};

export default Attachment;
