import * as React from 'react';
import clsx from 'clsx';

const SortingIcon = ({className, ...props}) => (
  <svg
    className={clsx('w-2.5 h-2', className)}
    fill="none"
    viewBox="0 0 10 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.178 1.186a1 1 0 0 1 1.644 0l3.287 4.745A1 1 0 0 1 8.287 7.5H1.713a1 1 0 0 1-.822-1.57l3.287-4.744Z"
    />
  </svg>
);

export default SortingIcon;
