import * as React from 'react';
import clsx from 'clsx';

function SettingsIcon({className, ...props}) {
  return (
    <svg
      className={clsx('w-6 h-6', className)}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.988 15.291a4.23 4.23 0 110-8.46 4.23 4.23 0 010 8.46zm0-6.769a2.538 2.538 0 100 5.077 2.538 2.538 0 000-5.077zm.564 13.539h-1.128a2.54 2.54 0 01-2.538-2.539.41.41 0 00-.248-.383h-.01a.373.373 0 00-.406.08 2.585 2.585 0 01-3.61.024l-.79-.796a2.54 2.54 0 010-3.596.413.413 0 00.087-.443.38.38 0 00-.348-.242 2.547 2.547 0 01-2.573-2.541v-1.129a2.54 2.54 0 012.539-2.538.406.406 0 00.382-.248V7.7a.374.374 0 00-.081-.405 2.538 2.538 0 01-.024-3.61l.8-.8a2.595 2.595 0 013.59 0 .413.413 0 00.451.096.377.377 0 00.24-.347 2.547 2.547 0 012.54-2.573h1.127a2.54 2.54 0 012.539 2.538.386.386 0 00.237.376.408.408 0 00.432-.071 2.528 2.528 0 011.81-.763 2.522 2.522 0 011.796.743l.796.79a2.538 2.538 0 010 3.59.387.387 0 00-.102.434l.006.016a.375.375 0 00.347.237 2.548 2.548 0 012.575 2.545v1.129a2.541 2.541 0 01-2.538 2.538.386.386 0 00-.376.237.412.412 0 00.071.432 2.538 2.538 0 01.02 3.61l-.79.797a2.595 2.595 0 01-3.59 0 .388.388 0 00-.435-.103.399.399 0 00-.252.353 2.55 2.55 0 01-2.546 2.572zm-3.169-4.437a2.107 2.107 0 011.195 1.867.855.855 0 00.846.877h1.128a.846.846 0 00.847-.846 2.112 2.112 0 011.297-1.95 2.057 2.057 0 012.249.45.852.852 0 001.22.024l.797-.79a.848.848 0 000-1.199 2.115 2.115 0 01-.466-2.267v-.016l.008-.02a2.055 2.055 0 011.907-1.274.852.852 0 00.876-.846v-1.129a.846.846 0 00-.846-.846 2.074 2.074 0 01-1.94-1.275 2.072 2.072 0 01.446-2.288.866.866 0 00.272-.622.837.837 0 00-.248-.598l-.79-.797a.847.847 0 00-1.198.002 2.119 2.119 0 01-2.268.466l-.019-.003-.02-.008a2.054 2.054 0 01-1.274-1.908.853.853 0 00-.846-.875h-1.128a.846.846 0 00-.847.846A2.074 2.074 0 019.29 4.544a2.1 2.1 0 01-2.266-.442.854.854 0 00-1.218-.024l-.8.8a.846.846 0 000 1.197 2.074 2.074 0 01-.347 3.247c-.33.21-.71.323-1.1.328a.854.854 0 00-.878.846v1.129a.846.846 0 00.846.846 2.068 2.068 0 011.938 1.28l.007.014.005.016a2.11 2.11 0 01-.45 2.256.845.845 0 00-.018 1.213l.797.79a.867.867 0 001.197 0 2.075 2.075 0 012.278-.469c.035.016.069.034.102.053z"
        fill="#585550"
      />
    </svg>
  );
}

export default SettingsIcon;
