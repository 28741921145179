import loadable from '@loadable/component';
import {loadableRootParams} from '@components/Skeleton/loadableParams';

const Principal = {
  Organization: loadable(() => import('./Organization'), loadableRootParams),
  Users: loadable(() => import('./Users/UsersTable'), loadableRootParams),
  Statistics: loadable(() => import('./Statistics'), loadableRootParams),
  ChangeUser: loadable(() => import('./Users/ChangeUser'), loadableRootParams),
  ProfileUser: loadable(() => import('./Users/ProfileUser'), loadableRootParams),
};

export default Principal;
