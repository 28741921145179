export const ADMIN_ROOT = '/';
export const ADMIN_HOME = `${ADMIN_ROOT}organizations`;
export const ADMIN_SETTINGS = `${ADMIN_ROOT}settings`;
export const ADMIN_SIGN_IN = '/sign-in';
export const REDIRECT = '*';

export const ADD_ORGANIZATION = `${ADMIN_HOME}/add`;
export const EDIT_ORGANIZATION = `${ADMIN_HOME}/:id/edit`;

export const ADMIN_COUNSELLORS = `${ADMIN_ROOT}counsellors`;
export const ADMIN_ADD_COUNSELLORS = `${ADMIN_COUNSELLORS}/add`;
export const ADMIN_VIEW_COUNSELLOR = `${ADMIN_COUNSELLORS}/:id`;
export const ADMIN_EDIT_COUNSELLORS = `${ADMIN_COUNSELLORS}/:id/edit`;
