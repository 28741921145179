import AllbryCounsellor from '@pages/AllbryCounsellor';
import {ACCOUNT_SELECTION} from '@router/consts';

export const ALLBRY_COUNSELLOR_ROUTES = [
  {
    name: 'accountSelection',
    path: ACCOUNT_SELECTION,
    exact: true,
    component: AllbryCounsellor.AccountSelection,
  },
];
