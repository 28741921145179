import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';

import {PopupButton} from '@components/Static';
import {Checkbox} from '@assets/icons';

const NavPopupButton = ({
  Icon, label, path, onClick, textClassName, text, isSelected, className,
}) => {
  const history = useHistory();
  const {t} = useTranslation();

  const onRedirect = () => {
    if (path) {
      history.push(path);
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <PopupButton className={clsx('flex w-full flex-row justify-between my-1', className)} onClick={onRedirect}>
      <div className="flex flex-row items-center">
        {Icon && (
        <div className="w-6 flex justify-center items-center">
          <Icon />
        </div>
        )}
        <div className={clsx('text-lg text-dawn-gray', Icon ? 'ml-5' : '', textClassName)}>{text || t(label)}</div>
      </div>
      {isSelected && <Checkbox size="h-6 w-6" />}
    </PopupButton>
  );
};

export default NavPopupButton;
