import {UserContext} from '@contexts/User';
import {useContext, useMemo} from 'react';
import {Link, useHistory} from 'react-router-dom';
import clsx from 'clsx';
import {SurveyArrow} from '@assets/icons';
import {useTranslation} from 'react-i18next';

const NavbarList = ({navItems}) => {
  const history = useHistory();
  const {t} = useTranslation();
  const {afterSurveyWrapStep} = useContext(UserContext);
  const currentPage = history.location?.pathname.split('/')[1];

  const surveyStep = useMemo(() => navItems.find(({name}) => name === afterSurveyWrapStep), [afterSurveyWrapStep]);

  const setActiveSvg = (name) => {
    if (name === surveyStep?.name) {
      return 'text-white relative z-40 pointer-events-none';
    }
    if (!surveyStep && currentPage === name) {
      return 'text-dawn-gray';
    }
    return 'text-sonic-silver';
  };

  const setTextClass = (name) => {
    if (name === surveyStep?.name) {
      return 'text-white z-40 relative';
    }
    if (!surveyStep && currentPage === name) {
      return 'text-dawn-gray';
    }
    return 'text-sonic-silver';
  };

  return (
    navItems.map((item) => (
      <Link
        key={item.name}
        to={item.path}
        className={clsx('group duration-100 w-full flex flex-col items-center py-6 relative', (item.name === currentPage || (currentPage === '' && item.name === 'home')) && 'bg-pampas')}
      >
        <div className="mb-2">
          <item.icon
            {...(item.name === 'messages' ? {isActiveTab: !!(item.name === currentPage || (currentPage === '' && item.name === 'home'))} : {})}
            className={clsx('group-hover:text-dawn-gray fill-current text-sonic-silver', setActiveSvg(item.name), !surveyStep ? 'transition' : '')}
          />
        </div>
        <span
          className={clsx('leading-8 group-hover:text-opacity-100 font-medium-rubik capitalize', !surveyStep ? 'transition text-opacity-80' : '', setTextClass(item.name))}
        >
          {t(`navbar.${item.name}.title`)}
        </span>
        {item.name === surveyStep?.name
          && (
            <div className="absolute z-40 left-1/3 top-3/4 pointer-events-none">
              <SurveyArrow className="w-72" />
              <div className="relative left-1/2">
                <p className="text-white text-3xl font-bold-rubik mb-3">{t(`navbar.${surveyStep.name}.title`)}</p>
                <p className="text-white text-xl">{t(`navbar.${surveyStep.name}.text`)}</p>
              </div>
            </div>
          )}
      </Link>
    ))
  );
};

export default NavbarList;
