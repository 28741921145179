function SurveyOutro() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 814 777" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M387.842 579.314C452.437 587.655 518.248 581.752 573.097 556.882C574.789 556.131 576.514 555.315 578.134 554.519C624.988 531.892 690.837 481.79 683.408 418.991C682.331 409.753 679.375 400.121 673.186 392.256C666.662 383.925 657.229 378.42 647.944 373.948C637.76 369.005 627.331 364.939 616.891 361.465C532.743 333.801 462.005 264.059 429.764 180.563C414.514 140.768 387.293 105.722 350.154 82.6945C167.85 -30.3402 42.1864 205.846 92.0969 358.49C134.895 489.155 264.196 563.241 387.842 579.314Z" fill="#FFE8CC" />
      <path d="M233.062 438.5C198.541 426.425 168.179 398.593 149.187 369.354C130.205 340.095 121.032 309.336 113.895 279.413C99.6521 219.813 107.793 164.564 133.449 119.376C157.138 77.6449 190.673 28.9317 243.846 13.2249C263.946 7.30532 282.609 8.23945 306.903 19.9042C322.087 27.185 337.495 35.939 352.899 38.2712C373.261 41.3604 388.97 32.5993 402.07 23.1981C423.38 7.93004 443.38 -1.25432 476.639 1.31434C533.863 5.73817 597.73 33.0374 644.698 75.782C691.667 118.526 719.725 176.225 713.404 222.715C709.64 250.355 694.448 271.831 683.149 295.543C673.095 316.652 660.977 330.718 667.333 359.169C672.107 380.51 681.967 402.475 684.117 423.372C689.562 476.288 651.857 522.129 606.472 542.539C566.982 560.296 486.584 567.215 448.001 511.694C438.647 498.26 430.977 483.763 417.863 472.395C406.769 462.771 392.804 456.362 379.199 452.545C328.013 438.236 284.278 456.391 233.062 438.5Z" fill="#FFF0DE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M139.962 373.943C159.542 402.435 190.845 429.555 226.436 441.321C254.211 450.492 279.853 449.944 305.602 449.393C328.802 448.897 352.089 448.399 377.102 455.008C391.128 458.727 405.526 464.972 416.964 474.35C427.224 482.757 434.252 492.919 441.26 503.052C443.486 506.272 445.711 509.488 448.036 512.645C479.637 555.624 538.445 560.206 581.701 551.598C585.313 543.916 588.589 536.287 591.607 528.892C610.892 481.652 610.787 427.234 587.194 371.841C575.363 344.029 561.329 315.703 537.52 290.234C513.706 264.786 478.51 242.329 441.472 235.657C412.568 230.461 387.293 234.58 361.912 238.717C339.044 242.444 316.089 246.185 290.319 243.138C275.865 241.416 260.649 237.248 247.872 229.568C236.412 222.685 227.874 213.614 219.361 204.569C216.656 201.696 213.953 198.825 211.161 196.027C182.639 167.405 142.315 162.357 106.449 167.785C94.4429 203.565 92.8292 243.797 103.576 286.3C110.934 315.459 120.391 345.432 139.962 373.943Z" fill="#FFE8CC" />
      <g clipPath="url(#clip0_5838_30249)">
        <path d="M338.034 258.191C331.515 285.938 316.075 350.174 309.262 378.483C307.375 386.296 311.835 394.23 319.482 396.704C327.594 399.324 336.27 394.891 338.892 386.785L377.247 267.767C380.115 256.502 372.885 245.163 361.488 242.983C350.852 240.951 340.51 247.661 338.034 258.191Z" fill="#FFE5DC" />
        <path d="M346.725 283.889C336.089 281.856 325.746 288.566 323.271 299.072C322.854 300.86 322.389 302.795 321.898 304.876C323.05 305.856 324.129 307.007 325.084 308.427C335.476 323.488 342.167 340.606 346.455 358.361L362.459 308.697C365.351 297.407 358.146 286.068 346.725 283.889Z" fill="#F3A396" />
        <path fillRule="evenodd" clipRule="evenodd" d="M435.714 108.054C434.905 108.446 434.807 106.707 435.445 105.384C435.273 105.727 436.621 100.731 431.376 99.7274C426.499 98.7968 427.455 104.258 419.833 106.266C415.029 107.515 412.333 103.621 408.314 101.858C398.584 97.6213 385.767 98.2825 379.052 107.295C376.772 110.356 375.596 113.907 373.611 117.115C371.944 119.784 369.665 120.592 367.435 122.649C365.474 124.462 364.788 127.523 364.763 130.094C364.714 134.845 365.744 139.596 366.43 144.274C366.675 145.914 365.572 146.086 365.278 147.261C364.886 148.976 364.91 150.225 365.082 151.988C365.278 153.873 365.205 155.808 365.376 157.694C365.793 162.739 369.763 164.086 373.513 166.73C374.125 167.171 378.782 171.114 379.493 170.771C379.934 170.575 382.213 155.514 382.213 155.122C382.262 149.637 383.095 143.735 387.066 139.963C391.208 136.021 398.634 136.364 403.413 137.343C413.216 139.4 425.127 145.572 434.734 139.253C438.435 136.804 442.797 132.788 442.233 127.302C442.062 125.662 441.376 124.07 439.856 123.8C439.292 123.703 438.631 124.021 438.116 124.241C437.822 124.364 441.89 121.254 443.532 119.245C444.513 118.045 445.493 116.674 446.106 115.18C447.429 111.997 447.723 107.76 443.557 106.633C440.836 105.899 438.091 106.927 435.714 108.054Z" fill="#213044" />
        <path d="M409.916 761.571L411.73 700.25L387.418 698.927L385.555 760.176L409.916 761.571Z" fill="#FFE5DC" />
        <g filter="url(#filter0_d_5838_30249)">
          <path d="M406.166 744.674H389.035C387.589 744.674 386.192 745.164 385.065 746.07L368.669 757.335C364.846 760.004 362.518 764.559 362.518 769.433V772.151L417.808 771.857V764.976C417.808 762.429 417.17 759.931 415.97 757.727L412.024 748.592C410.994 746.192 408.691 744.674 406.166 744.674Z" fill="#B7ABA7" />
        </g>
        <path d="M475.916 760.861L474.176 699.54L449.815 699.638L451.506 760.886L475.916 760.861Z" fill="#FFE5DC" />
        <path d="M446.948 750.895L442.316 771.343H523.683V770.756C523.683 766.054 520.963 761.743 516.723 759.735L478.956 744.674C477.608 744.038 475.843 744.062 474.814 745.14C469.937 750.282 464.57 758.658 449.669 749.572C448.615 748.911 447.194 749.646 446.948 750.895Z" fill="#B7ABA7" />
        <path d="M442.809 776.238V771.811H523.24V776.238H442.809Z" fill="#FAF1F1" stroke="black" strokeWidth="0.935895" />
        <path d="M490.734 569.159C490.464 565.339 490.023 561.518 489.386 557.723C485.93 537.445 482.597 517.168 479.215 496.866C475.613 475.34 472.574 454.206 467.549 432.949C466.839 429.937 463.04 400.77 462.501 400.77H367.091C363.954 400.819 361.283 450.238 361.258 455.479C361.16 474.312 364.42 492.924 366.307 511.609C368.561 533.943 370.816 556.278 373.095 578.612C373.12 579.151 373.193 579.69 373.267 580.228L380.178 734.708L416.818 731.426L415.2 578.49C415.2 578.22 415.176 577.951 415.176 577.681L418.509 467.014L449.438 575.379C449.095 576.824 448.899 578.318 448.874 579.861L440.762 732.601L477.23 737.426C480.808 696.382 486.224 655.509 489.239 614.391C490.293 599.355 491.788 584.171 490.734 569.159Z" fill="#F0A890" />
        <path d="M389.535 673.159C389.51 645.363 388.408 617.886 385.957 590.189C380.492 528.72 373.384 467.521 386.937 406.543C387.06 405.979 387.207 405.441 387.378 404.926C387.256 403.09 387.109 401.253 386.888 399.416H368.728C365.591 399.465 362.919 448.885 362.895 454.126C362.797 472.958 366.056 491.57 367.943 510.255C370.198 532.59 372.453 554.924 374.732 577.258C374.757 577.797 374.83 578.336 374.904 578.875L381.79 733.354L395.686 732.105C390.319 713.052 389.559 693.142 389.535 673.159Z" fill="#DB8463" />
        <path d="M442.954 732.209C442.954 732.209 453.713 733.63 463.565 734.928C465.845 693.173 471.972 651.541 471.432 609.615C470.722 554.808 450.478 507.543 431.264 456.654C430.038 455.136 430.161 460.793 428.813 459.103C425.578 460.328 422.245 460.328 419.01 461.552C419.475 472.352 421.461 471.348 424.72 480.605L451.654 574.963C451.311 576.408 451.115 577.901 451.091 579.444L442.954 732.209Z" fill="#DB8463" />
        <path d="M524.614 96.7083C529.246 98.349 531.942 97.5654 531.942 97.5654C534.442 101.067 539.368 101.753 542.726 99.0592L545.103 97.1736C548.216 94.7001 548.828 90.2186 546.525 86.986L546.402 86.79C546.402 86.79 542.579 76.0392 533.168 66.023C521.012 53.0926 509.321 52.7008 505.596 56.7905C502.067 60.6353 515.62 76.6024 520.571 82.6024L512.581 79.835C511.576 79.4922 510.449 79.835 509.812 80.6922C509.125 81.6228 509.174 82.8962 509.934 83.7778L515.963 90.6594C518.291 93.3287 521.232 95.5083 524.614 96.7083Z" fill="#FFE5DC" />
        <path d="M531.378 97.59L558.19 200.592C560.518 209.678 570.322 214.625 578.998 211.098C586.84 207.915 590.688 199.025 587.6 191.14L548.387 91.6146L531.378 97.59Z" fill="#FFE5DC" />
        <path d="M450.992 235.882C477.216 224.617 537.824 198.242 564.538 186.634C571.915 183.426 580.493 186.414 584.267 193.516C588.262 201.034 585.419 210.364 577.87 214.331L467.266 272.788C456.678 277.563 444.228 272.445 440.086 261.596C436.238 251.482 441.042 240.143 450.992 235.882Z" fill="#FFE5DC" />
        <path opacity="0.37" d="M545.226 106.822C552.113 127.761 562.577 147.255 574.881 165.475C575.224 165.989 575.518 166.503 575.812 167.017C576.596 167.091 577.38 167.14 578.165 167.238L548.387 91.6146L538.167 95.2145C538.658 96.341 539.099 97.492 539.564 98.6185C542.015 100.504 544.049 103.198 545.226 106.822Z" fill="#F3A396" />
        <path opacity="0.37" d="M584.488 203.335C565.567 222.951 527.727 228.78 504.42 233.727C499.665 234.731 495.548 233.874 492.239 231.89C479.422 239.751 467.266 248.763 457.315 259.808C452.438 265.22 446.532 266.102 441.385 264.289C446.409 273.252 457.61 277.171 467.266 272.787L577.87 214.306C580.983 212.666 583.287 210.094 584.635 207.131C585.468 206.2 586.154 205.196 586.742 204.119C586.326 203.972 585.885 203.849 585.443 203.678C585.149 203.556 584.806 203.458 584.488 203.335Z" fill="#F3A396" />
        <path d="M526.306 252.387C520.179 236.102 514.297 219.694 507.631 203.629C490.255 211.588 470.967 220.796 451.434 220.33C433.617 219.914 414.525 219.914 396.487 223.245C381.807 225.938 366.612 229.587 353.647 237.253C337.006 247.097 332.962 260.811 328.06 278.468C327.497 280.452 320.095 308.296 319.801 308.247L359.749 316.623L352.032 417.487L420.921 417.512L477.819 417.487C477.819 417.487 461.544 270.46 472.437 269.946C463.463 269.921 526.306 252.387 526.306 252.387Z" fill="#B1CBE9" />
        <path d="M411.24 238.778C401.168 240.027 392.1 233.758 390.972 224.746L386.634 190.02L423.078 185.465L427.416 220.191C428.568 229.203 421.313 237.505 411.24 238.778Z" fill="#FFE5DC" />
        <path d="M387.566 200.054C387.566 200.054 400.139 221.506 411.486 219.278C429.034 215.8 423.642 196.527 423.642 196.527C423.642 196.527 418.348 208.674 407.491 210.045C395.384 211.564 387.566 200.054 387.566 200.054Z" fill="#F3A396" />
        <path d="M372.541 177.867L364.87 152.079C360.508 138.781 374.698 129.696 383.815 128.569L415.381 124.627C425.062 123.427 437.463 129.133 434.473 145.1L434.547 168.683L372.541 177.867Z" fill="#213044" />
        <path d="M428.274 173.148C434.793 177.041 445.503 158.625 439.131 154.389C432.759 150.176 428.789 158.16 428.789 158.16V164.87L428.005 171.164L427.686 173.099L428.274 173.148Z" fill="#FFE5DC" />
        <path d="M378.632 179.185C372.088 183.912 357.089 167.112 363.388 162.091C369.686 157.071 375.225 164.565 375.225 164.565L378.24 170.491L378.558 177.226L379.244 179.112L378.632 179.185Z" fill="#FFE5DC" />
        <path d="M433.347 148.944C435.013 162.217 434.67 173.899 433.935 182.225C433.199 190.405 429.205 197.947 422.906 203.286L421.754 204.266C415.088 209.898 405.726 211.196 397.785 207.547C389.771 203.874 383.644 197.066 380.973 188.715C376.12 173.556 373.645 156.389 373.645 156.389C373.645 156.389 366.66 123.255 398.962 119.875C431.239 116.496 433.347 148.944 433.347 148.944Z" fill="#FFE5DC" />
        <path d="M429.995 111.292C418.623 105.307 398.583 112.943 387.987 119.396C387.812 116.071 385.783 116.687 385.783 116.687C366.645 124.347 373.784 139.077 373.784 139.077C373.784 139.077 373.86 139.102 373.985 139.151C372.482 143.831 370.979 153.561 375.688 170.286C375.688 170.286 386.885 152.452 385.633 141.442C394.901 144.176 411.885 149.225 426.914 142.107C429.67 151.295 435.331 162.945 435.331 162.945C435.581 151.713 433.878 143.905 432.099 138.732C432.976 137.944 433.803 137.082 434.529 136.121C434.529 136.097 446.127 119.766 429.995 111.292Z" fill="#213044" />
        <path d="M413.569 162.131L414.882 179.349C414.928 179.934 414.905 180.481 414.813 181.027C414.49 183.136 413.201 184.385 411.934 184.112L409.194 183.487" stroke="black" strokeWidth="0.935895" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M399.991 166.944C400.236 168.952 399.354 170.74 398.006 170.911C396.658 171.082 395.359 169.588 395.089 167.556C394.844 165.548 395.726 163.76 397.074 163.589C398.447 163.417 399.746 164.911 399.991 166.944Z" fill="#1A1A1A" />
        <path d="M422.71 164.104C422.955 166.112 422.073 167.9 420.725 168.071C419.377 168.242 418.078 166.749 417.808 164.716C417.563 162.708 418.446 160.92 419.794 160.749C421.166 160.577 422.465 162.096 422.71 164.104Z" fill="#1A1A1A" />
        <path d="M394.587 189.31C394.587 189.31 396.649 192.772 403.488 194.641C409.717 196.374 414.202 194.735 414.202 194.735" stroke="black" strokeWidth="0.935895" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M403.079 159.304L402.81 155.998L393.595 157.369C392.443 157.54 391.34 157.981 390.507 158.593L387.149 161.875L393.202 160.43L403.079 159.304Z" fill="#1A1A1A" />
        <path d="M416.313 157.834L415.872 154.553L424.18 153.5C425.234 153.377 426.263 153.5 427.121 153.891L430.724 156.218L425.161 156.389L416.313 157.834Z" fill="#1A1A1A" />
        <g filter="url(#filter1_d_5838_30249)">
          <path d="M419.01 771.612H362.641V776.975H419.01V771.612Z" fill="#FAF1F1" />
          <path d="M363.109 776.507V772.08H418.542V776.507H363.109Z" stroke="black" strokeWidth="0.935895" />
        </g>
        <path opacity="0.22" d="M524.394 771.979C524.394 767.277 521.674 762.967 517.434 760.959L505.498 756.208C501.111 758.755 498.146 763.481 498.146 768.918C498.146 770.192 498.318 771.416 498.636 772.592H524.419V771.979H524.394Z" fill="#FAF1F1" />
        <path opacity="0.22" d="M389.722 768.11C389.722 760.028 383.179 753.465 375.091 753.416L368.793 757.751C364.969 760.42 362.641 764.975 362.641 769.848V772.567L389.061 772.42C389.477 771.073 389.722 769.603 389.722 768.11Z" fill="#FAF1F1" />
        <path d="M461.531 375.129L377.689 389.308L355.093 260.788L438.935 246.609L461.531 375.129Z" fill="#7799B5" />
        <path d="M455.772 366.362L375.704 394.525L330.781 271.784L410.848 243.621L455.772 366.362Z" fill="#E3E2E9" />
        <path d="M423.764 328.33C423.323 333.203 424.744 335.628 424.744 335.628C421.975 338.909 422.538 343.856 425.97 346.427L428.396 348.264C431.582 350.664 436.067 350.15 438.616 347.113L438.763 346.942C438.763 346.942 448.247 340.575 455.6 328.967C465.109 313.979 455.208 305.016 450.355 302.42C445.748 299.971 440.993 314.591 436.41 320.861L437.096 312.436C437.194 311.359 436.557 310.355 435.577 309.963C434.498 309.522 433.273 309.889 432.636 310.844L427.465 318.387C425.455 321.375 424.082 324.754 423.764 328.33Z" fill="#FFE5DC" />
        <path d="M427.93 334.33L326.442 366.631C317.496 369.447 313.085 379.488 317.055 387.961C320.658 395.627 329.75 398.982 337.47 395.48L434.816 350.958L427.93 334.33Z" fill="#FFE5DC" />
        <path opacity="0.37" d="M391.585 359.92C373.694 365.969 355.926 374.957 336.981 377.112C328.599 378.067 319.703 376.279 312.62 372.238C312.301 373.561 312.007 374.834 311.713 376.034C309.826 383.846 314.286 391.781 321.933 394.254C325.143 395.307 328.428 395.209 331.417 394.254C334.211 394.646 337.152 394.279 339.922 393.03L437.268 348.508L433.665 339.814C420.553 348.165 406.633 354.851 391.585 359.92Z" fill="#F3A396" />
        <path d="M383.154 129.206C383.399 133.663 388.595 136.651 392.393 137.826C396.682 139.149 401.461 138.953 405.701 137.508C415.21 134.276 421.19 125.337 424.646 116.374C425.21 114.904 422.832 114.268 422.293 115.713C419.328 123.451 414.279 131.312 406.363 134.643C402.809 136.137 398.741 136.651 394.942 135.916C393.08 135.549 391.291 134.912 389.673 133.933C388.056 132.953 385.727 131.288 385.629 129.206C385.531 127.639 383.08 127.639 383.154 129.206Z" fill="white" />
        <path d="M407.392 137.435C416.803 140.423 427.734 136.088 432.586 127.517C433.371 126.145 431.238 124.896 430.479 126.292C426.141 133.958 416.436 137.753 408.054 135.084C406.534 134.594 405.897 136.97 407.392 137.435Z" fill="white" />
        <path d="M375.728 129.916C376.512 134.838 375.753 140.006 373.596 144.487C372.91 145.908 375.042 147.157 375.704 145.712C378.154 140.569 378.988 134.863 378.081 129.255C377.983 128.594 377.149 128.226 376.561 128.398C375.875 128.618 375.605 129.279 375.728 129.916Z" fill="white" />
        <path d="M419.155 463.756C419.792 463.903 420.062 464.222 419.964 464.736C419.425 465.152 419.302 465.299 419.572 465.226C419.645 465.201 419.645 465.201 419.572 465.226C419.67 465.226 419.743 465.226 419.841 465.226C420.209 465.226 420.552 465.226 420.92 465.226C421.704 465.226 422.464 465.226 423.248 465.226C424.792 465.226 426.336 465.226 427.905 465.25C429.473 465.25 429.473 462.801 427.905 462.801C425.65 462.801 423.371 462.777 421.116 462.777C420.038 462.777 418.69 462.581 417.93 463.536C417.121 464.54 417.783 466.303 419.18 466.23C419.841 466.181 420.405 465.691 420.405 465.005C420.381 464.344 419.817 463.732 419.155 463.756Z" fill="#1A1A1A" />
        <path d="M429.253 434.809C428.984 444.408 428.714 454.008 428.469 463.608C428.42 465.176 430.871 465.176 430.92 463.608C431.189 454.008 431.459 444.408 431.704 434.809C431.753 433.241 429.302 433.241 429.253 434.809Z" fill="#1A1A1A" />
        <path d="M426.803 464.687C437.194 491.258 446.385 518.294 454.325 545.698C456.556 553.436 458.712 561.224 460.746 569.036C461.139 570.555 463.516 569.918 463.099 568.375C455.869 540.775 447.414 513.519 437.709 486.678C434.964 479.087 432.121 471.544 429.18 464.026C428.592 462.581 426.215 463.217 426.803 464.687Z" fill="#1A1A1A" />
        <path d="M460.477 569.427C459.742 589.876 458.541 610.3 456.899 630.7C455.257 651.002 453.149 671.254 450.625 691.458C449.154 703.042 447.561 714.625 445.797 726.184C445.551 727.727 447.929 728.388 448.149 726.845C451.237 706.617 453.86 686.291 456.041 665.94C458.247 645.394 459.962 624.798 461.237 604.178C461.947 592.619 462.511 581.035 462.928 569.452C463.001 567.86 460.55 567.86 460.477 569.427Z" fill="#1A1A1A" />
        <path d="M358.597 293.603C374.551 290.224 390.335 286.183 405.946 281.505C407.441 281.065 406.804 278.689 405.284 279.154C389.673 283.832 373.865 287.873 357.935 291.252C356.391 291.571 357.053 293.922 358.597 293.603Z" fill="#1A1A1A" />
        <path d="M363.302 305.261C377.713 302.959 391.977 299.922 406.069 296.126C407.588 295.71 406.951 293.359 405.407 293.775C391.315 297.571 377.051 300.632 362.641 302.91C361.097 303.155 361.758 305.506 363.302 305.261Z" fill="#1A1A1A" />
        <path d="M369.111 319.22C382.468 314.371 396.339 310.942 410.456 309.081C412 308.885 412.024 306.436 410.456 306.632C396.119 308.518 382.051 311.922 368.474 316.869C366.979 317.383 367.616 319.758 369.111 319.22Z" fill="#1A1A1A" />
        <path d="M378.743 331.464C389.012 329.456 399.281 327.423 409.55 325.415C411.094 325.121 410.432 322.746 408.888 323.064C398.619 325.072 388.35 327.105 378.081 329.113C376.537 329.407 377.199 331.758 378.743 331.464Z" fill="#1A1A1A" />
        <path d="M484.519 749.327C485.279 749.155 485.671 748.347 485.499 747.612C485.352 746.976 484.74 746.535 484.102 746.51C482.803 746.461 482.313 748.102 481.848 749.008C480.867 750.967 479.862 752.951 478.882 754.91C478.588 755.473 478.711 756.257 479.323 756.575C479.887 756.869 480.696 756.747 480.99 756.135C481.652 754.812 482.313 753.49 482.975 752.167C483.294 751.506 483.637 750.869 483.955 750.208C484.127 749.89 484.274 749.547 484.445 749.229C484.519 749.057 484.592 748.91 484.691 748.739C484.715 748.69 484.764 748.616 484.789 748.567C484.715 748.714 484.592 748.812 484.421 748.861C484.2 748.861 483.98 748.861 483.759 748.861C483.343 748.665 483.122 748.396 483.073 748.004C483.122 747.808 483.196 747.588 483.245 747.392C483.294 747.343 483.343 747.269 483.367 747.22C483.539 747.122 483.735 747 483.906 746.902C483.882 746.902 483.857 746.902 483.857 746.927C483.22 747.074 482.803 747.833 482.999 748.445C483.22 749.131 483.857 749.473 484.519 749.327Z" fill="#1A1A1A" />
        <path d="M488.858 749.303C487.804 751.482 486.725 753.662 485.672 755.841C485.402 756.405 485.5 757.188 486.113 757.507C486.652 757.8 487.485 757.678 487.779 757.066C488.833 754.886 489.911 752.707 490.965 750.527C491.235 749.964 491.137 749.18 490.524 748.862C489.985 748.568 489.176 748.666 488.858 749.303Z" fill="#1A1A1A" />
        <path d="M381.168 751.017C381.07 751.14 381.193 750.993 381.242 750.944C381.364 750.846 381.046 750.993 381.315 750.895C381.389 750.87 381.462 750.846 381.34 750.87C381.438 750.846 381.536 750.821 381.634 750.797C381.756 750.772 381.511 750.797 381.732 750.772C381.83 750.772 381.928 750.772 382.051 750.772C382.149 750.772 382.271 750.772 382.369 750.797C382.222 750.772 382.369 750.797 382.418 750.821C383.325 751.017 383.889 751.311 384.746 751.825C386.339 752.78 387.687 754.127 388.668 755.67C389.035 756.233 389.746 756.454 390.334 756.111C390.898 755.793 391.143 754.984 390.775 754.446C389.452 752.364 387.614 750.601 385.433 749.425C383.398 748.323 380.629 747.638 379.036 749.793C378.644 750.331 378.938 751.164 379.477 751.458C380.114 751.825 380.776 751.556 381.168 751.017Z" fill="#1A1A1A" />
        <path d="M384.085 747.857C384.38 747.882 384.429 747.882 384.625 747.931C384.87 747.98 385.115 748.029 385.36 748.078C385.801 748.176 386.218 748.323 386.634 748.469C387.345 748.739 388.276 749.253 389.012 749.792C389.257 749.988 389.649 750.282 389.943 750.6C390.286 750.918 390.605 751.286 390.899 751.629C390.948 751.702 390.899 751.629 390.997 751.751C391.07 751.849 391.144 751.971 391.242 752.069C391.364 752.265 391.487 752.437 391.609 752.633C391.732 752.829 391.83 753.024 391.928 753.22C392.002 753.343 392.051 753.465 392.1 753.588C392.124 753.612 392.247 753.931 392.198 753.784C392.443 754.396 393.006 754.837 393.717 754.641C394.305 754.469 394.82 753.759 394.575 753.122C392.81 748.812 388.766 745.8 384.11 745.359C383.448 745.31 382.885 745.971 382.885 746.584C382.86 747.343 383.424 747.784 384.085 747.857Z" fill="#1A1A1A" />
        <path d="M452.29 304.918C450.991 307.195 449.693 309.473 448.394 311.75C448.001 312.436 449.055 313.048 449.447 312.362C450.746 310.085 452.045 307.807 453.344 305.53C453.761 304.844 452.683 304.232 452.29 304.918Z" fill="black" />
        <path d="M455.795 308.591C454.496 310.795 453.198 313 451.874 315.179C451.482 315.865 452.536 316.477 452.928 315.791C454.227 313.587 455.526 311.383 456.849 309.204C457.266 308.542 456.187 307.906 455.795 308.591Z" fill="black" />
        <path d="M458.247 313.489C456.948 315.693 455.649 317.897 454.325 320.077C453.933 320.763 454.987 321.375 455.379 320.689C456.678 318.485 457.977 316.281 459.3 314.102C459.717 313.44 458.639 312.804 458.247 313.489Z" fill="black" />
        <path d="M467.399 253.634C467.938 258.728 468.477 263.821 469.041 268.94C469.114 269.601 469.555 270.164 470.266 270.164C470.879 270.164 471.565 269.601 471.491 268.94C470.952 263.846 470.413 258.752 469.849 253.634C469.776 252.973 469.335 252.409 468.624 252.409C468.011 252.409 467.325 252.973 467.399 253.634Z" fill="white" />
        <path d="M383.625 230.762C387.024 237.521 392.142 242.713 398.1 245.236C404.784 248.076 412.155 247.342 418.495 243.497C419.603 242.836 418.629 240.705 417.54 241.391C406.063 248.321 392.142 243.154 385.267 229.513C384.618 228.215 382.956 229.44 383.625 230.762Z" fill="white" />
        <path d="M500.449 208.575C504.346 220.991 508.267 233.408 512.164 245.824C512.63 247.318 515.007 246.681 514.517 245.163C510.62 232.746 506.699 220.33 502.802 207.914C502.336 206.42 499.984 207.057 500.449 208.575Z" fill="white" />
        <path d="M323.796 302.346C327.815 304.109 331.835 305.872 335.854 307.66C336.467 307.929 337.177 307.831 337.52 307.219C337.814 306.705 337.692 305.823 337.079 305.554C333.06 303.791 329.041 302.027 325.021 300.24C324.409 299.97 323.698 300.068 323.355 300.68C323.061 301.17 323.183 302.076 323.796 302.346Z" fill="white" />
        <path d="M428.592 316.771C425.43 322.158 424.131 328.525 424.965 334.721C425.063 335.48 426.288 335.505 426.19 334.721C425.406 328.721 426.607 322.623 429.646 317.383C430.062 316.722 428.984 316.085 428.592 316.771Z" fill="black" />
        <path d="M409.867 340.966C415.186 339.619 420.259 337.366 424.817 334.305C425.479 333.864 424.866 332.811 424.205 333.252C419.744 336.264 414.793 338.468 409.549 339.79C408.764 339.962 409.083 341.162 409.867 340.966Z" fill="black" />
        <path d="M311.002 373.536C309.042 381.079 312.056 389.234 318.698 393.422C321.982 395.503 325.952 396.483 329.825 396.164C334.334 395.773 338.549 393.911 342.642 392.148C347.789 389.92 352.789 387.397 357.69 384.654C358.376 384.263 357.764 383.209 357.077 383.601C352.372 386.271 347.519 388.695 342.569 390.875C337.643 393.03 332.3 395.479 326.786 394.964C316.468 394.009 309.581 383.822 312.179 373.904C312.375 373.12 311.198 372.777 311.002 373.536Z" fill="black" />
        <path d="M576.155 165.597C578.385 171.205 580.591 176.838 582.821 182.446C584.855 187.613 587.036 192.854 586.375 198.536C585.1 209.605 575.861 217.319 565.935 220.919C565.2 221.188 565.518 222.364 566.253 222.094C573.998 219.278 581.228 214.356 585.051 206.862C586.914 203.213 587.943 199.099 587.673 194.985C587.379 190.381 585.541 186.095 583.875 181.858C581.694 176.324 579.512 170.814 577.307 165.279C577.061 164.544 575.861 164.863 576.155 165.597Z" fill="black" />
        <path d="M521.894 93.9166C524.369 95.9493 527.726 95.386 530.398 96.9288C533.069 98.4717 534.172 101.729 535.079 104.496C535.324 105.231 536.5 104.912 536.255 104.178C535.299 101.288 534.197 98.1533 531.648 96.2921C530.373 95.3615 528.829 94.9697 527.285 94.6758C525.717 94.3575 524.026 94.1126 522.751 93.0595C522.163 92.5697 521.281 93.4269 521.894 93.9166Z" fill="black" />
        <path d="M506.037 58.1123C510.056 61.1001 513.732 64.5041 517.016 68.251C517.531 68.8387 518.413 67.9816 517.874 67.3938C514.492 63.5735 510.742 60.1205 506.625 57.0593C506.037 56.5695 505.424 57.6226 506.037 58.1123Z" fill="black" />
        <path d="M509.076 55.6887C513.095 59.4601 517.09 63.207 521.109 66.9783C521.673 67.5171 522.555 66.66 521.967 66.1212C517.948 62.3498 513.953 58.603 509.933 54.8316C509.37 54.2928 508.487 55.15 509.076 55.6887Z" fill="black" />
        <path d="M515.031 56.1788C520.08 60.6114 524.516 65.7052 528.217 71.3133C528.658 71.9745 529.712 71.3622 529.27 70.701C525.521 64.995 521.036 59.8032 515.889 55.3217C515.301 54.8074 514.419 55.6645 515.031 56.1788Z" fill="black" />
        <path d="M528.585 86.4965C529.933 86.7659 531.281 86.3006 532.285 85.37C533.241 84.4883 533.854 83.1659 533.707 81.868C533.658 81.5496 533.462 81.2557 533.094 81.2557C532.8 81.2557 532.432 81.5496 532.481 81.868C532.506 82.0149 532.506 82.1374 532.506 82.2843C532.506 82.3333 532.506 82.4067 532.506 82.4557C532.506 82.5047 532.506 82.5292 532.506 82.5782C532.506 82.5047 532.506 82.5292 532.506 82.6027C532.457 82.8475 532.383 83.1169 532.285 83.3373C532.334 83.2394 532.261 83.4108 532.261 83.4108C532.236 83.4843 532.187 83.5333 532.163 83.6067C532.089 83.7292 532.04 83.8271 531.967 83.9496C531.844 84.121 531.697 84.2924 531.501 84.4883C531.183 84.7822 530.692 85.0761 530.325 85.223C530.202 85.272 530.055 85.2965 529.933 85.3455C529.884 85.37 529.81 85.37 529.761 85.37C529.59 85.3944 529.884 85.37 529.688 85.37C529.541 85.37 529.418 85.3944 529.271 85.37C529.222 85.37 529.148 85.37 529.099 85.3455C529.026 85.3455 529.026 85.3455 529.075 85.3455C529.026 85.3455 529.001 85.321 528.952 85.321C528.634 85.2475 528.291 85.4189 528.193 85.7373C528.095 86.0312 528.266 86.423 528.585 86.4965Z" fill="black" />
      </g>
      <g clipPath="url(#clip1_5838_30249)">
        <path opacity="0.5" d="M146.553 185.514C180.456 185.514 207.939 158.062 207.939 124.198C207.939 90.3333 180.456 62.8809 146.553 62.8809C112.65 62.8809 85.1665 90.3333 85.1665 124.198C85.1665 158.062 112.65 185.514 146.553 185.514Z" fill="#E96C5F" />
        <path d="M153.869 180.866C187.772 180.866 215.256 153.413 215.256 119.549C215.256 85.6849 187.772 58.2325 153.869 58.2325C119.967 58.2325 92.4829 85.6849 92.4829 119.549C92.4829 153.413 119.967 180.866 153.869 180.866Z" fill="#FCB96B" />
        <path d="M151.054 68.2312C151.054 66.6677 152.304 65.3992 153.843 65.3992C155.385 65.3992 156.632 66.6677 156.632 68.2312C156.632 69.7948 155.381 71.0671 153.843 71.0671C152.304 71.0671 151.054 69.7948 151.054 68.2312Z" fill="white" />
        <path d="M173.571 72.2365C174.32 70.8684 176.019 70.374 177.366 71.1366C178.712 71.8954 179.2 73.6238 178.448 74.9919C177.7 76.36 176 76.8544 174.653 76.0918C173.31 75.3291 172.823 73.6046 173.571 72.2365Z" fill="white" />
        <path d="M192.866 87.8034C194.162 86.9565 195.889 87.332 196.722 88.6503C197.558 89.9648 197.186 91.72 195.889 92.5669C194.592 93.4139 192.866 93.0383 192.029 91.72C191.197 90.4055 191.569 88.6503 192.866 87.8034Z" fill="white" />
        <path d="M202.423 111.893C203.965 111.893 205.212 113.161 205.212 114.728C205.212 116.292 203.962 117.561 202.423 117.561C200.881 117.561 199.634 116.292 199.634 114.728C199.63 113.161 200.881 111.893 202.423 111.893Z" fill="white" />
        <path d="M197.542 139.85C198.632 140.957 198.632 142.751 197.542 143.858C196.453 144.966 194.684 144.966 193.595 143.858C192.505 142.751 192.505 140.957 193.595 139.85C194.684 138.742 196.453 138.742 197.542 139.85Z" fill="white" />
        <path d="M180.017 157.693C181.107 158.8 181.107 160.594 180.017 161.701C178.927 162.809 177.159 162.809 176.069 161.701C174.98 160.594 174.98 158.8 176.069 157.693C177.159 156.585 178.927 156.585 180.017 157.693Z" fill="white" />
        <path d="M151.054 166.89C151.054 165.326 152.304 164.054 153.843 164.054C155.385 164.054 156.632 165.323 156.632 166.89C156.632 168.454 155.381 169.726 153.843 169.726C152.304 169.722 151.054 168.454 151.054 166.89Z" fill="white" />
        <path d="M134.027 72.2362C133.279 70.8681 131.58 70.3737 130.233 71.1364C128.886 71.8952 128.399 73.6235 129.151 74.9916C129.899 76.3598 131.599 76.8541 132.945 76.0915C134.292 75.3289 134.776 73.6044 134.027 72.2362Z" fill="white" />
        <path d="M114.737 87.8031C113.44 86.9562 111.713 87.3317 110.877 88.65C110.04 89.9645 110.413 91.7197 111.709 92.5666C113.006 93.4136 114.733 93.038 115.569 91.7197C116.405 90.4052 116.029 88.65 114.737 87.8031Z" fill="white" />
        <path d="M105.179 111.893C103.637 111.893 102.39 113.162 102.39 114.729C102.39 116.293 103.641 117.561 105.179 117.561C106.722 117.561 107.969 116.293 107.969 114.729C107.969 113.162 106.722 111.893 105.179 111.893Z" fill="white" />
        <path d="M110.059 139.849C108.97 140.957 108.97 142.75 110.059 143.858C111.149 144.965 112.918 144.965 114.007 143.858C115.097 142.75 115.097 140.957 114.007 139.849C112.914 138.742 111.149 138.742 110.059 139.849Z" fill="white" />
        <path d="M127.585 157.693C126.496 158.8 126.496 160.594 127.585 161.701C128.675 162.809 130.444 162.809 131.533 161.701C132.623 160.594 132.623 158.8 131.533 157.693C130.44 156.585 128.675 156.585 127.585 157.693Z" fill="white" />
        <path d="M150.305 120.672C150.305 118.982 151.844 117.614 153.743 117.614C155.642 117.614 157.18 118.982 157.18 120.672C157.18 122.359 155.642 123.727 153.743 123.727C151.844 123.727 150.305 122.359 150.305 120.672Z" fill="white" stroke="white" strokeWidth="4.67948" strokeMiterlimit="10" />
        <path d="M152.316 82.9549V120.201C152.316 120.99 152.957 121.627 153.743 121.627C154.534 121.627 155.17 120.987 155.17 120.201V82.9549C155.17 82.1655 154.53 81.5293 153.743 81.5293C152.957 81.5293 152.316 82.1693 152.316 82.9549Z" fill="white" />
        <path d="M180.224 117.871L155.098 119.052C153.748 119.117 152.754 120.339 152.969 121.673C153.153 122.807 154.162 123.627 155.313 123.574L180.439 122.393C181.79 122.328 182.783 121.106 182.569 119.772C182.384 118.634 181.375 117.814 180.224 117.871Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_d_5838_30249" x="358.774" y="744.674" width="62.7772" height="34.9642" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.74358" />
          <feGaussianBlur stdDeviation="1.87179" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5838_30249" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5838_30249" result="shape" />
        </filter>
        <filter id="filter1_d_5838_30249" x="358.898" y="771.612" width="63.8558" height="12.8503" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.74358" />
          <feGaussianBlur stdDeviation="1.87179" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5838_30249" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5838_30249" result="shape" />
        </filter>
        <clipPath id="clip0_5838_30249">
          <rect width="279.833" height="722.511" fill="white" transform="translate(308.846 54.4881)" />
        </clipPath>
        <clipPath id="clip1_5838_30249">
          <rect width="130.089" height="127.282" fill="white" transform="translate(85.167 58.2317)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default SurveyOutro;
