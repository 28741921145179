import {
  useEffect, useMemo, useState, useContext, useCallback, useRef,
} from 'react';

import LocalStorageService from '@services/localStorage';
import ChatService from '@services/api/chat';
import {UserContext} from '@contexts/User';
import {ChatContext} from '@contexts/Messages';
import ProfileService from '@services/api/profile';
import {generateUUID} from '@utils/helpers';
import ResourceService from '@services/api/resource';
import {CHAT_MESSAGE_TYPES} from '@utils/consts';

const prepareChatRoom = async (chatRoomParam, userProfile, populateChatParticipantProfiles) => {
  const {messageCount, ...chatRoom} = {...chatRoomParam};
  let unreadMessageCount = messageCount ? Number(messageCount) : 0;

  if (chatRoom.participants) {
    const currentUserParticipant = chatRoom.participants.find((participant) => participant.userId === userProfile.userId);
    if (currentUserParticipant) {
      const sendMessageCount = currentUserParticipant.sendMessageCount ? Number(currentUserParticipant.sendMessageCount) : 0;
      const readMessageCount = currentUserParticipant.readMessageCount ? Number(currentUserParticipant.readMessageCount) : 0;
      unreadMessageCount = unreadMessageCount - sendMessageCount - readMessageCount;

      chatRoom.participants = chatRoom.participants.filter((participant) => participant.userId !== userProfile.userId);
    }

    if (populateChatParticipantProfiles) {
      const participantProfiles = chatRoom.participants.map((participant) => ProfileService.getProfileByUserId(participant.accountId, participant.userId));
      chatRoom.participantProfiles = await Promise.all(participantProfiles);
    }
  }

  if (chatRoom.lastMessage) {
    chatRoom.lastMessage.sentAt = Number(chatRoom.lastMessage.sentAt);
  } else {
    chatRoom.lastMessage = {
      sentAt: Number(chatRoom.createdAt),
      messageText: '',
      type: CHAT_MESSAGE_TYPES.TEXT,
    };
  }

  return {
    ...chatRoom,
    unreadMessageCount,
    profile: (chatRoom.participantProfiles && chatRoom.participantProfiles[0]) || null,
  };
};

const prepareChatMessage = async (chatMessage) => {
  if ((chatMessage.type === CHAT_MESSAGE_TYPES.TEXT_ATTACHMENT || chatMessage.type === CHAT_MESSAGE_TYPES.ATTACHMENT) && !chatMessage.attachments?.length) {
    const attachments = (await ResourceService.getResourcesByEntity(chatMessage.accountId, chatMessage.id, 'chatAttachment'))
      .map((v) => ({...v, size: Number(v.size)}));

    return {
      ...chatMessage,
      attachments,
      sentAt: Number(chatMessage.sentAt),
    };
  }

  return {
    ...chatMessage,
    sentAt: Number(chatMessage.sentAt),
  };
};

export const useChatList = ({
  isPopulateChatParticipants = false,
  isPopulateChatParticipantProfiles = false,
  isPopulateLastMessage = false,
  allowReadMessageEventEmitting = false,
  isCacheEnabled = false,
} = {}, filterFn) => {
  const [chatList, setChatList] = useState(() => {
    if (isCacheEnabled) {
      const cache = LocalStorageService.getChatRooms();

      if (cache?.length) {
        return cache;
      }
    }

    return undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const pendingNewChats = useRef([]);
  const {profile} = useContext(UserContext);
  const {socket, openedChatRoomId} = useContext(ChatContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  useEffect(() => () => {
    isUnmounted.current = true;
  }, []);

  useEffect(() => {
    if (isCacheEnabled && chatList && isCacheMounted.current) {
      LocalStorageService.setChatRooms(chatList);
      return;
    }

    isCacheMounted.current = true;
  }, [chatList]);

  useEffect(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const chatRoomsResponse = await ChatService.getChatRoomsByUserId(
      profile.accountId,
      profile.userId,
      isPopulateLastMessage,
      isPopulateChatParticipants,
    );

    let preparedChatRooms = chatRoomsResponse.map((chatRoom) => prepareChatRoom(chatRoom, profile, isPopulateChatParticipantProfiles));
    preparedChatRooms = (await Promise.all(preparedChatRooms))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a.lastMessage.sentAt < b.lastMessage.sentAt)
        ? 1
        : ((b.lastMessage.sentAt < a.lastMessage.sentAt)
          ? -1
          : 0)));

    if (filterFn && typeof filterFn === 'function') {
      preparedChatRooms = filterFn(preparedChatRooms);
    }

    if (!isUnmounted.current) {
      setChatList(preparedChatRooms);
      setIsLoading(false);
    }
  }, [profile]);

  useEffect(() => {
    if (!socket?.current || socket.current.disconnected || !profile
      || !openedChatRoomId || !allowReadMessageEventEmitting) {
      return;
    }

    socket.current.emit('readMessages', openedChatRoomId);
  }, [openedChatRoomId, socket]);

  useEffect(() => {
    if (!socket?.current || socket.current.disconnected || !profile) {
      return;
    }

    const newChatRoomHandler = async (chatRoom) => {
      if (chatList.find((chat) => chat.id === chatRoom.id)) {
        return;
      }

      if (chatRoom.participants.length < 2) {
        pendingNewChats.current = [...pendingNewChats.current, chatRoom];
        return;
      }

      const preparedChatRoom = await prepareChatRoom(chatRoom, profile, isPopulateChatParticipantProfiles);

      if (isUnmounted.current) {
        return;
      }

      setChatList((prev) => (prev
        ? [preparedChatRoom, ...prev]
        : [preparedChatRoom]));
    };

    const newChatParticipantHandler = async (chatParticipant) => {
      // this is used so we don't create new item in the list with one participant while adding participant
      const pendingChat = pendingNewChats.current.find((v) => v.id === chatParticipant.chatRoomId);
      if (pendingChat) {
        if (chatList.find((chat) => chat.id === pendingChat.id)) {
          pendingNewChats.current = pendingNewChats.current.filter((v) => v.id !== pendingChat.id);
          return;
        }

        const newChat = {
          ...pendingChat,
          participants: pendingChat.participants
            ? [...pendingChat.participants, chatParticipant]
            : [chatParticipant],
        };

        const preparedChatRoom = await prepareChatRoom(newChat, profile, isPopulateChatParticipantProfiles);

        if (!isUnmounted.current) {
          setChatList((prev) => [preparedChatRoom, ...prev]);
          pendingNewChats.current = pendingNewChats.current.filter((v) => v.id !== newChat.id);
        }
      }
    };

    const newMessageHandler = (chatMessage) => {
      const newMessageChat = chatList.find((chat) => chat.id === chatMessage.chatRoomId);
      if (!newMessageChat) {
        return;
      }

      if (chatMessage.senderId !== profile.userId) {
        if (chatMessage.chatRoomId === openedChatRoomId) {
          if (allowReadMessageEventEmitting) {
            socket.current.emit('readMessages', chatMessage.chatRoomId);
          }
        } else {
          newMessageChat.unreadMessageCount += 1;
        }
      }

      newMessageChat.lastMessage = {
        ...chatMessage,
        sentAt: Number(chatMessage.sentAt),
      };

      if (!isUnmounted.current) {
        setChatList((prev) => [newMessageChat, ...prev.filter((chat) => chat.id !== chatMessage.chatRoomId)]);
      }
    };

    const messagesReadHandler = (readEvent) => {
      if (!chatList) {
        return;
      }

      const chatListCopy = [...chatList];

      const readMessagesChat = chatListCopy.find((chat) => chat.id === readEvent.chatRoomId);
      if (!readMessagesChat || readMessagesChat.unreadMessageCount === 0) {
        return;
      }

      readMessagesChat.unreadMessageCount = 0;

      if (!isUnmounted.current) {
        setChatList(chatListCopy);
      }
    };

    const chatRoomDeletedHandler = async (chatRoomId) => {
      if (!chatList.some((chat) => chat.id === chatRoomId)) {
        return;
      }

      if (!isUnmounted.current) {
        setChatList((prev) => prev.filter((chat) => chat.id !== chatRoomId));
      }
    };

    socket.current.on('newChatRoom', newChatRoomHandler);
    socket.current.on('newChatParticipant', newChatParticipantHandler);
    socket.current.on('newMessage', newMessageHandler);
    socket.current.on('messagesRead', messagesReadHandler);
    socket.current.on('chatRoomDeleted', chatRoomDeletedHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off('newChatRoom', newChatRoomHandler);
      socket.current.off('newChatParticipant', newChatParticipantHandler);
      socket.current.off('newMessage', newMessageHandler);
      socket.current.off('messagesRead', messagesReadHandler);
      socket.current.off('chatRoomDeleted', chatRoomDeletedHandler);
    };
  }, [
    chatList,
    socket,
    profile,
    openedChatRoomId,
    isPopulateChatParticipantProfiles,
    allowReadMessageEventEmitting,
  ]);

  const updateChatRoom = useCallback(async (accountId, chatRoom, cb) => {
    const chatListCopy = [...chatList];
    const updatingChatIndex = chatListCopy.findIndex((chat) => chat.id === chatRoom.id);
    if (updatingChatIndex === -1) {
      return;
    }

    const updateResponse = await ChatService.updateChatRoom(accountId, chatRoom);
    chatListCopy[updatingChatIndex] = {
      ...chatListCopy[updatingChatIndex],
      isArchived: updateResponse.isArchived,
      organizerName: updateResponse.organizerName,
    };

    setChatList(chatListCopy);
    if (cb && typeof cb === 'function') {
      cb();
    }
  }, [chatList]);

  return useMemo(() => {
    // TODO: investigate why sometimes there are chat duplicates
    const chats = chatList
      ? chatList.filter((chat, index) => chatList.findIndex((c) => c.id === chat.id) === index)
      : [];
    return [chats, {isLoading, updateChatRoom}];
  }, [chatList, isLoading]);
};

export const useChatMessages = (chatRoomId, filterFn) => {
  const [chatMessages, setChatMessages] = useState(() => {
    const cache = LocalStorageService.getChatRoomMessages(chatRoomId);

    return cache ?? undefined;
  });

  const [isLoading, setIsLoading] = useState(true);
  const {profile} = useContext(UserContext);
  const {socket} = useContext(ChatContext);
  const isUnmounted = useRef();
  const isCacheMounted = useRef();

  useEffect(() => () => {
    isUnmounted.current = true;
  }, []);

  useEffect(() => {
    if (chatMessages && isCacheMounted.current) {
      LocalStorageService.setChatRoomMessages(chatRoomId, chatMessages);
      return;
    }

    isCacheMounted.current = true;
  }, [chatMessages]);

  useEffect(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const chatRoomMessagesResponse = await ChatService.getChatRoomMessages(
      profile.accountId,
      chatRoomId,
    );

    const preparedChatMessagesPromises = chatRoomMessagesResponse.map((chatMessage) => prepareChatMessage(chatMessage));
    let preparedChatMessages = (await Promise.all(preparedChatMessagesPromises))
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a.sentAt < b.sentAt)
        ? 1
        : ((b.sentAt < a.sentAt)
          ? -1
          : 0)));

    if (filterFn && typeof filterFn === 'function') {
      preparedChatMessages = filterFn(preparedChatMessages);
    }

    if (!isUnmounted.current) {
      setChatMessages(preparedChatMessages);
      setIsLoading(false);
    }
  }, [profile, chatRoomId]);

  useEffect(() => {
    if (!socket?.current || socket.current.disconnected) {
      return;
    }

    const newMessageHandler = async (chatMessage) => {
      if (chatMessage.chatRoomId !== chatRoomId) {
        return;
      }

      const existingMessage = chatMessages?.find((v) => v.id === chatMessage.id);
      if (existingMessage) {
        if (!existingMessage.isPending) {
          return;
        }

        const preparedMessage = await prepareChatMessage({...chatMessage, attachments: existingMessage.attachments, isPending: false});
        if (!isUnmounted.current) {
          setChatMessages((prev) => [preparedMessage, ...prev.filter((v) => v.id !== preparedMessage.id)]);
        }

        return;
      }

      const preparedNewMessage = await prepareChatMessage(chatMessage);

      if (!isUnmounted.current) {
        setChatMessages((prev) => (prev
          ? [preparedNewMessage, ...prev]
          : [preparedNewMessage]));
      }
    };

    socket.current.on('newMessage', newMessageHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off('newMessage', newMessageHandler);
    };
  }, [chatMessages, socket, profile, chatRoomId]);

  const createChatMessage = useCallback(async (newMessage, cb) => {
    const messageId = generateUUID();
    const messageAttachments = newMessage.attachments?.length
      ? newMessage.attachments.map((attachment) => ({
        id: attachment.fileName.split('.')[0],
        accountId: newMessage.accountId,
        entityId: messageId,
        entityType: 'chatAttachment',
        resourceName: attachment.originalFileName.split('.')[0],
        resourceType: attachment.fileName.split('.').pop(),
        contentType: attachment.mimetype,
        size: attachment.size,
      }))
      : [];

    const preparedNewMessage = await prepareChatMessage({
      id: messageId,
      ...newMessage,
      attachments: messageAttachments,
    });

    setChatMessages((prev) => (prev
      ? [{...preparedNewMessage, isPending: true}, ...prev]
      : [{...preparedNewMessage, isPending: true}]));

    const attachmentsPromises = messageAttachments.map((attachment) => ResourceService.createResource(
      preparedNewMessage.accountId,
      attachment,
    ));

    await Promise.all(attachmentsPromises);
    await ChatService.createChatRoomMessage(
      preparedNewMessage.accountId,
      {
        id: preparedNewMessage.id,
        accountId: preparedNewMessage.accountId,
        senderName: preparedNewMessage.senderName,
        senderId: preparedNewMessage.senderId,
        chatRoomId: preparedNewMessage.chatRoomId,
        messageText: preparedNewMessage.messageText,
        type: preparedNewMessage.type,
        sentAt: preparedNewMessage.sentAt,
      },
    );

    if (cb && typeof cb === 'function') {
      cb();
    }
  }, [chatMessages]);

  return useMemo(() => {
    // TODO: investigate why sometimes there are message duplicates
    const messages = chatMessages
      ? chatMessages.filter(
        (message, index) => chatMessages.findIndex((m) => m.id === message.id) === index,
      )
      : [];
    return [messages, {createChatMessage, isLoading}];
  }, [chatMessages, isLoading]);
};

export const useChatRoomCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isUnmounted = useRef();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  const createChatRoom = useCallback(async (newChat, chatParticipant, cb) => {
    setIsLoading(true);

    const chatRoomResponse = await ChatService.createChatRoom(
      newChat.accountId,
      {
        isArchived: newChat.isArchived,
        organizerName: newChat.organizerName,
        organizerId: newChat.organizerId,
        accountId: newChat.accountId,
      },
    );

    await ChatService.createChatRoomParticipant(
      chatRoomResponse.accountId,
      {
        chatRoomId: chatRoomResponse.id,
        accountId: chatRoomResponse.accountId,
        name: chatParticipant.name,
        userId: chatParticipant.userId,
      },
    );

    if (!isUnmounted.current) {
      setIsLoading(false);
    }

    if (cb && typeof cb === 'function') {
      cb(chatRoomResponse);
    }
  }, []);

  return [createChatRoom, {isLoading}];
};

export const useChatRoomDelete = () => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteChatRoom = useCallback(async (accountId, chatRoomId, cb) => {
    setIsLoading(true);

    await ChatService.deleteChatRoom(accountId, chatRoomId);

    setIsLoading(false);

    if (cb && typeof cb === 'function') {
      cb();
    }
  }, []);

  return [deleteChatRoom, {isLoading}];
};

export const useChatAttachments = (chatRoomId, filterFn) => {
  const [attachments, setAttachments] = useState();
  const {profile} = useContext(UserContext);
  const {socket} = useContext(ChatContext);
  const [isLoading, setIsLoading] = useState(true);

  const isUnmounted = useRef();

  useEffect(() => () => {
    isUnmounted.current = true;
  }, []);

  useEffect(async () => {
    if (!profile) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    let chatAttachmentsResponse = await ChatService.getChatRoomAttachments(profile.accountId, chatRoomId);

    if (isUnmounted.current) {
      return;
    }

    chatAttachmentsResponse = chatAttachmentsResponse.map((attachment) => ({
      ...attachment,
      sentAt: Number(attachment.sentAt),
      attachmentSize: Number(attachment.attachmentSize),
    }))
    // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => ((a.sentAt < b.sentAt)
        ? 1
        : ((b.sentAt < a.sentAt)
          ? -1
          : 0)));

    if (filterFn && typeof filterFn === 'function') {
      chatAttachmentsResponse = filterFn(chatAttachmentsResponse);
    }

    setAttachments(chatAttachmentsResponse);
    setIsLoading(false);
  }, [profile, chatRoomId]);

  useEffect(() => {
    if (!socket?.current || socket.current.disconnected) {
      return;
    }

    const newChatAttachmentHandler = async (chatAttachment) => {
      if (chatAttachment.chatRoomId !== chatRoomId) {
        return;
      }

      const preparedChatAttachment = {...chatAttachment, sentAt: Number(chatAttachment.sentAt)};

      if (!isUnmounted.current) {
        setAttachments((prev) => (prev
          ? [preparedChatAttachment, ...prev]
          : [preparedChatAttachment]));
      }
    };

    socket.current.on('newChatAttachment', newChatAttachmentHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.current.off('newChatAttachment', newChatAttachmentHandler);
    };
  }, [attachments, socket, profile, chatRoomId]);

  // TODO: investigate why sometimes there are attachments duplicates
  return useMemo(() => {
    const result = attachments
      ? attachments.filter(
        (attachment, index) => attachments.findIndex((a) => a.chatRoomId === attachment.chatRoomId
        && a.attachmentId === attachment.attachmentId) === index,
      )
      : [];

    return [result, {isLoading}];
  },
  [attachments, isLoading]);
};
