export const appointmentReportOptions = [
  {label: 'reports.topics.startOfSession', value: 'Start of the session', color: '#FF7F50'},
  {label: 'reports.topics.checkIn', value: 'Check-in', color: '#CD5C5C'},
  {label: 'reports.topics.basicCare', value: 'Basic care', color: '#F08080'},
  {label: 'reports.topics.identity', value: 'Identity', color: '#E9967A'},
  {label: 'reports.topics.anxiety', value: 'Anxiety', color: '#FA8072'},
  {label: 'reports.topics.stress', value: 'Stress', color: '#FFA07A'},
  {label: 'reports.topics.physicalHealth', value: 'Physical health', color: '#B8860B'},
  {label: 'reports.topics.training', value: 'Training', color: '#DAA520'},
  {label: 'reports.topics.crime', value: 'Crime', color: '#EEE8AA'},
  {label: 'reports.topics.sorrow', value: 'Sorrow', color: '#BDB76B'},
  {label: 'reports.topics.socialRelationship', value: 'Social relationship', color: '#F0E68C'},
  {label: 'reports.topics.workLife', value: 'Work life', color: '#808000'},
  {label: 'reports.topics.shame', value: 'Shame', color: '#9ACD32'},
  {label: 'reports.topics.honor', value: 'Honor', color: '#556B2F'},
  {label: 'reports.topics.careFailure', value: 'Child neglect', color: '#6B8E23'},
  {label: 'reports.topics.economy', value: 'Economy', color: '#008000'},
  {label: 'reports.topics.security', value: 'Security', color: '#228B22'},
  {label: 'reports.topics.stimulationAndGuidance', value: 'Stimulation and guidance', color: '#90EE90'},
  {label: 'reports.topics.socialOrientation', value: 'Social orientation', color: '#BC8F8F'},
  {label: 'reports.topics.emotionalAvailability', value: 'Emotional availability', color: '#8FBC8F'},
  {label: 'reports.topics.love', value: 'Love', color: '#2E8B57'},
  {label: 'reports.topics.mentalHealth', value: 'Mental health', color: '#66CDAA'},
  {label: 'reports.topics.mentalAbuse', value: 'Mental abuse', color: '#3CB371'},
  {label: 'reports.topics.physicalAbuse', value: 'Physical abuse', color: '#20B2AA'},
  {label: 'reports.topics.loveRelations', value: 'Love relations', color: '#FFDEAD'},
  {label: 'reports.topics.familyRelations', value: 'Family relations', color: '#AFEEEE'},
  {label: 'reports.topics.sexualAbuse', value: 'Sexual abuse', color: '#B0E0E6'},
  {label: 'reports.topics.economicalAbuse', value: 'Financial abuse', color: '#4682B4'},
  {label: 'reports.topics.drugUse', value: 'Drug use', color: '#6495ED'},
  {label: 'reports.topics.performanceAnxiety', value: 'Performance anxiety', color: '#D2691E'},
  {label: 'reports.topics.loneliness', value: 'Loneliness', color: '#DEB887'},
  {label: 'reports.topics.grooming', value: 'Grooming', color: '#D2B48C'},
];
